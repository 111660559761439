export const MEMBER_REQUEST = { type: "MEMBER_REQUEST" }
export const MEMBER_REQUEST_SUCCESS = { type: "MEMBER_REQUEST_SUCCESS" }
export const MEMBER_REQUEST_FAIL = { type: "MEMBER_REQUEST_FAIL" }
export const MEMBER_SUCCESS = { type: "MEMBER_SUCCESS" }
export const MEMBER_FAIL = { type: "MEMBER_FAIL" }
export const SET_MEMBER = { type: "SET_MEMBER" }
export const MEMBER_SET_STEP = { type: "MEMBER_SET_STEP" }

export const MEMBER_UPDATE_FIRSTNAME = { type: "MEMBER_UPDATE_FIRSTNAME" }
export const MEMBER_UPDATE_FIRSTNAME_SUCCESS = {
    type: "MEMBER_UPDATE_FIRSTNAME_SUCCESS",
}

export const MEMBER_UPDATE_FIRSTNAME_FAIL = {
    type: "MEMBER_UPDATE_FIRSTNAME_FAIL",
}
export const MEMBER_UPDATE_LASTNAME = { type: "MEMBER_UPDATE_LASTNAME" }
export const MEMBER_UPDATE_LASTNAME_SUCCESS = {
    type: "MEMBER_UPDATE_LASTNAME_SUCCESS",
}
export const MEMBER_UPDATE_LASTNAME_FAIL = {
    type: "MEMBER_UPDATE_LASTNAME_FAIL",
}
export const MEMBER_UPDATE_EMAIL = { type: "MEMBER_UPDATE_EMAIL" }
export const MEMBER_UPDATE_EMAIL_SUCCESS = {
    type: "MEMBER_UPDATE_EMAIL_SUCCESS",
}
export const MEMBER_UPDATE_EMAIL_FAIL = { type: "MEMBER_UPDATE_EMAIL_FAIL" }
export const MEMBER_UPDATE_PHONE = { type: "MEMBER_UPDATE_PHONE" }
export const MEMBER_UPDATE_PHONE_SUCCESS = {
    type: "MEMBER_UPDATE_PHONE_SUCCESS",
}
export const MEMBER_UPDATE_PHONE_FAIL = { type: "MEMBER_UPDATE_PHONE_FAIL" }
export const MEMBER_UPDATE_ADDRESS = { type: "MEMBER_UPDATE_ADDRESS" }
export const MEMBER_UPDATE_ADDRESS_SUCCESS = {
    type: "MEMBER_UPDATE_ADDRESS_SUCCESS",
}
export const MEMBER_UPDATE_ADDRESS_FAIL = {
    type: "MEMBER_UPDATE_ADDRESS_FAIL",
}
export const MEMBER_UPDATE_AVATAR = { type: "MEMBER_UPDATE_AVATAR" }
export const MEMBER_UPDATE_AVATAR_SUCCESS = {
    type: "MEMBER_UPDATE_AVATAR_SUCCESS",
}
export const MEMBER_UPDATE_AVATAR_FAIL = {
    type: "MEMBER_UPDATE_AVATAR_FAIL",
}
export const MEMBER_UPDATE_DATEOFBIRTH = { type: "MEMBER_UPDATE_DATEOFBIRTH" }
export const MEMBER_UPDATE_DATEOFBIRTH_SUCCESS = {
    type: "MEMBER_UPDATE_DATEOFBIRTH_SUCCESS",
}
export const MEMBER_UPDATE_DATEOFBIRTH_FAIL = {
    type: "MEMBER_UPDATE_DATEOFBIRTH_FAIL",
}
export const MEMBER_UPDATE_PASSWORD = { type: "MEMBER_UPDATE_PASSWORD" }
export const MEMBER_UPDATE_PASSWORD_SUCCESS = {
    type: "MEMBER_UPDATE_PASSWORD_SUCCESS",
}
export const MEMBER_UPDATE_PASSWORD_FAIL = {
    type: "MEMBER_UPDATE_PASSWORD_FAIL",
}
export const MEMBER_UPDATE_COMPLETE = { type: "MEMBER_UPDATE_COMPLETE" }
export const MEMBER_UPDATE_COMPLETE_SUCCESS = {
    type: "MEMBER_UPDATE_COMPLETE_SUCCESS",
}
export const MEMBER_UPDATE_COMPLETE_FAIL = {
    type: "MEMBER_UPDATE_COMPLETE_FAIL",
}
export const MEMBER_UPDATE_VERIFY = { type: "MEMBER_UPDATE_VERIFY" }
export const MEMBER_UPDATE_VERIFY_SUCCESS = {
    type: "MEMBER_UPDATE_VERIFY_SUCCESS",
}
export const MEMBER_UPDATE_VERIFY_FAIL = {
    type: "MEMBER_UPDATE_VERIFY_FAIL",
}
export const MEMBER_UPDATE_SEARCH_REQUIRED = {
    type: "MEMBER_UPDATE_SEARCH_REQUIRED",
}

/**
 * Middle names and preferred names
 */

export const MEMBER_UPDATE_MIDDLE_NAMES = { type: "MEMBER_UPDATE_MIDDLE_NAMES" }
export const MEMBER_UPDATE_MIDDLE_NAMES_SUCCESS = {
    type: "MEMBER_UPDATE_MIDDLE_NAMES_SUCCESS",
}
export const MEMBER_UPDATE_MIDDLE_NAMES_FAIL = {
    type: "MEMBER_UPDATE_MIDDLE_NAMES_FAIL",
}
export const MEMBER_UPDATE_PREFERRED_NAME = {
    type: "MEMBER_UPDATE_PREFERRED_NAME",
}
export const MEMBER_UPDATE_PREFERRED_NAME_SUCCESS = {
    type: "MEMBER_UPDATE_PREFERRED_NAME_SUCCESS",
}
export const MEMBER_UPDATE_PREFERRED_NAME_FAIL = {
    type: "MEMBER_UPDATE_PREFERRED_NAME_FAIL",
}

/**
 * CONFIRMED USER REVIEWED ADDRESSES
 */

export const MEMBER_CONFIRMED_REVIEWED_ADDRESSES = {
    type: "MEMBER_CONFIRMED_REVIEWED_ADDRESSES",
}
export const MEMBER_CONFIRMED_REVIEWED_ADDRESSES_SUCCESS = {
    type: "MEMBER_CONFIRMED_REVIEWED_ADDRESSES_SUCCESS",
}
export const MEMBER_CONFIRMED_REVIEWED_ADDRESSES_FAIL = {
    type: "MEMBER_CONFIRMED_REVIEWED_ADDRESSES_FAIL",
}

export const MEMBER_CONFIRM_BANK_CALLBACK = {
    type: "MEMBER_CONFIRM_BANK_CALLBACK",
}
export const MEMBER_CONFIRM_BANK_CALLBACK_SUCCESS = {
    type: "MEMBER_CONFIRM_BANK_CALLBACK_SUCCESS",
}
export const MEMBER_CONFIRM_BANK_CALLBACK_FAIL = {
    type: "MEMBER_CONFIRM_BANK_CALLBACK_FAIL",
}
export const MEMBER_CONFIRM_BANK_INITIALISE = {
    type: "MEMBER_CONFIRM_BANK_INITIALISE",
}
export const MEMBER_CONFIRM_BANK_INITIALISE_SUCCESS = {
    type: "MEMBER_CONFIRM_BANK_INITIALISE_SUCCESS",
}
export const MEMBER_CONFIRM_BANK_INITIALISE_FAIL = {
    type: "MEMBER_CONFIRM_BANK_INITIALISE_FAIL",
}

export const MEMBER_CONFIRM_BANK_SCHEDULE = {
    type: "MEMBER_CONFIRM_BANK_SCHEDULE",
}
export const MEMBER_CONFIRM_BANK_SCHEDULE_SUCCESS = {
    type: "MEMBER_CONFIRM_BANK_SCHEDULE_SUCCESS",
}
export const MEMBER_CONFIRM_BANK_SCHEDULE_FAIL = {
    type: "MEMBER_CONFIRM_BANK_SCHEDULE_FAIL",
}
export const MEMBER_CONFIRM_BANK_STATUS = {
    type: "MEMBER_CONFIRM_BANK_STATUS",
}
export const MEMBER_CONFIRM_BANK_STATUS_SUCCESS = {
    type: "MEMBER_CONFIRM_BANK_STATUS_SUCCESS",
}
export const MEMBER_CONFIRM_BANK_STATUS_FAIL = {
    type: "MEMBER_CONFIRM_BANK_STATUS_FAIL",
}
export const MEMBER_ACCOUNT_UPDATE_MARKETING_PREFERENCES = {
    type: "MEMBER_ACCOUNT_UPDATE_MARKETING_PREFERENCES",
}
export const MEMBER_ACCOUNT_UPDATE_MARKETING_PREFERENCES_SUCCESS = {
    type: "MEMBER_ACCOUNT_UPDATE_MARKETING_PREFERENCES_SUCCESS",
}
export const MEMBER_ACCOUNT_UPDATE_MARKETING_PREFERENCES_FAIL = {
    type: "MEMBER_ACCOUNT_UPDATE_MARKETING_PREFERENCES_FAIL",
}
export const MEMBERS_GET_THIRD_PARTY_RESULTS_CURRENT = {
    type: "MEMBERS_GET_THIRD_PARTY_RESULTS_CURRENT",
}
export const MEMBERS_GET_THIRD_PARTY_RESULTS_CURRENT_SUCCESS = {
    type: "MEMBERS_GET_THIRD_PARTY_RESULTS_CURRENT_SUCCESS",
}
export const MEMBERS_GET_THIRD_PARTY_RESULTS_CURRENT_FAIL = {
    type: "MEMBERS_GET_THIRD_PARTY_RESULTS_CURRENT_FAIL",
}
