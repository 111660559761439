exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-check-for-missing-bank-accounts-jsx": () => import("./../../../src/pages/check-for-missing-bank-accounts.jsx" /* webpackChunkName: "component---src-pages-check-for-missing-bank-accounts-jsx" */),
  "component---src-pages-claim-misplaced-investments-jsx": () => import("./../../../src/pages/claim-misplaced-investments.jsx" /* webpackChunkName: "component---src-pages-claim-misplaced-investments-jsx" */),
  "component---src-pages-clearscore-index-jsx": () => import("./../../../src/pages/clearscore/index.jsx" /* webpackChunkName: "component---src-pages-clearscore-index-jsx" */),
  "component---src-pages-complaints-jsx": () => import("./../../../src/pages/complaints.jsx" /* webpackChunkName: "component---src-pages-complaints-jsx" */),
  "component---src-pages-consent-success-jsx": () => import("./../../../src/pages/ConsentSuccess.jsx" /* webpackChunkName: "component---src-pages-consent-success-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-corporate-services-jsx": () => import("./../../../src/pages/corporate-services.jsx" /* webpackChunkName: "component---src-pages-corporate-services-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-feedback-jsx": () => import("./../../../src/pages/feedback.jsx" /* webpackChunkName: "component---src-pages-feedback-jsx" */),
  "component---src-pages-find-lost-pensions-jsx": () => import("./../../../src/pages/find-lost-pensions.jsx" /* webpackChunkName: "component---src-pages-find-lost-pensions-jsx" */),
  "component---src-pages-fraud-advice-jsx": () => import("./../../../src/pages/fraud-advice.jsx" /* webpackChunkName: "component---src-pages-fraud-advice-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-centre-jsx": () => import("./../../../src/pages/media-centre.jsx" /* webpackChunkName: "component---src-pages-media-centre-jsx" */),
  "component---src-pages-moneymagpie-index-jsx": () => import("./../../../src/pages/moneymagpie/index.jsx" /* webpackChunkName: "component---src-pages-moneymagpie-index-jsx" */),
  "component---src-pages-news-for-you-jsx": () => import("./../../../src/pages/news-for-you.jsx" /* webpackChunkName: "component---src-pages-news-for-you-jsx" */),
  "component---src-pages-portal-jsx": () => import("./../../../src/pages/portal.jsx" /* webpackChunkName: "component---src-pages-portal-jsx" */),
  "component---src-pages-privacy-notice-jsx": () => import("./../../../src/pages/privacy-notice.jsx" /* webpackChunkName: "component---src-pages-privacy-notice-jsx" */),
  "component---src-pages-reclaim-child-trust-fund-index-jsx": () => import("./../../../src/pages/reclaim-child-trust-fund/index.jsx" /* webpackChunkName: "component---src-pages-reclaim-child-trust-fund-index-jsx" */),
  "component---src-pages-search-for-lost-shares-jsx": () => import("./../../../src/pages/search-for-lost-shares.jsx" /* webpackChunkName: "component---src-pages-search-for-lost-shares-jsx" */),
  "component---src-pages-snoop-index-jsx": () => import("./../../../src/pages/snoop/index.jsx" /* webpackChunkName: "component---src-pages-snoop-index-jsx" */),
  "component---src-pages-stepchange-index-jsx": () => import("./../../../src/pages/stepchange/index.jsx" /* webpackChunkName: "component---src-pages-stepchange-index-jsx" */),
  "component---src-pages-terms-conditions-jsx": () => import("./../../../src/pages/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-jsx" */),
  "component---src-pages-trace-unclaimed-life-insurance-jsx": () => import("./../../../src/pages/trace-unclaimed-life-insurance.jsx" /* webpackChunkName: "component---src-pages-trace-unclaimed-life-insurance-jsx" */),
  "component---src-pages-wheres-my-child-trust-fund-jsx": () => import("./../../../src/pages/wheres-my-child-trust-fund.jsx" /* webpackChunkName: "component---src-pages-wheres-my-child-trust-fund-jsx" */),
  "component---src-pages-why-do-i-need-to-provide-this-information-jsx": () => import("./../../../src/pages/why-do-i-need-to-provide-this-information.jsx" /* webpackChunkName: "component---src-pages-why-do-i-need-to-provide-this-information-jsx" */),
  "component---src-templates-brand-listing-template-jsx": () => import("./../../../src/templates/BrandListingTemplate.jsx" /* webpackChunkName: "component---src-templates-brand-listing-template-jsx" */),
  "component---src-templates-brand-template-jsx": () => import("./../../../src/templates/BrandTemplate.jsx" /* webpackChunkName: "component---src-templates-brand-template-jsx" */),
  "component---src-templates-news-template-jsx": () => import("./../../../src/templates/NewsTemplate.jsx" /* webpackChunkName: "component---src-templates-news-template-jsx" */)
}

