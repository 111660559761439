export const GET_NOTIFICATIONS = { type: "GET_NOTIFICATIONS" }
export const GET_NOTIFICATIONS_SUCCESS = { type: "GET_NOTIFICATIONS_SUCCESS" }
export const GET_NOTIFICATIONS_FAIL = { type: "GET_NOTIFICATIONS_FAIL" }
export const CLEAR_NOTIFICATIONS = { type: "CLEAR_NOTIFICATIONS" }
export const CLEAR_NOTIFICATIONS_SUCCESS = {
    type: "CLEAR_NOTIFICATIONS_SUCCESS",
}
export const CLEAR_NOTIFICATIONS_FAIL = { type: "CLEAR_NOTIFICATIONS_FAIL" }
export const UPDATE_NOTIFICATION = { type: "UPDATE_NOTIFICATION" }
export const UPDATE_NOTIFICATION_SUCCESS = {
    type: "UPDATE_NOTIFICATION_SUCCESS",
}
export const UPDATE_NOTIFICATION_FAIL = { type: "UPDATE_NOTIFICATION_FAIL" }
