/**
 * Previous address request types
 */
export const GET_MEMBER_PREVIOUS_ADDRESSES = {
    type: "GET_MEMBER_PREVIOUS_ADDRESSES",
}
export const POST_MEMBER_ADDRESS_ADD = {
    type: "POST_MEMBER_ADDRESS_ADD",
}
export const POST_MEMBER_ADDRESS_REJECT = {
    type: "POST_MEMBER_ADDRESS_REJECT",
}
export const GET_MEMBER_ADDRESS = { type: "GET_MEMBER_ADDRESS" }
export const GET_MEMBER_REJECTED_ADDRESS = {
    type: "GET_MEMBER_REJECTED_ADDRESS",
}
/**
 * end of request previous address types
 */

/**
 * Previous address success types
 */
export const GET_MEMBER_PREVIOUS_ADDRESSES_SUCCESS = {
    type: "GET_MEMBER_PREVIOUS_ADDRESSES_SUCCESS",
}
export const POST_MEMBER_ADDRESS_ADD_SUCCESS = {
    type: "POST_MEMBER_ADDRESS_ADD_SUCCESS",
}
export const POST_MEMBER_ADDRESS_REJECT_SUCCESS = {
    type: "POST_MEMBER_ADDRESS_REJECT_SUCCESS",
}
export const GET_MEMBER_ADDRESS_SUCCESS = {
    type: "GET_MEMBER_ADDRESS_SUCCESS",
}
export const GET_MEMBER_REJECTED_ADDRESS_SUCCESS = {
    type: "GET_MEMBER_REJECTED_ADDRESS_SUCCESS",
}
/**
 * end of success previous address types
 */

/**
 * Previous address fail types
 */
export const GET_MEMBER_PREVIOUS_ADDRESSES_FAIL = {
    type: "GET_MEMBER_PREVIOUS_ADDRESSES_FAIL",
}
export const POST_MEMBER_ADDRESS_ADD_FAIL = {
    type: "POST_MEMBER_ADDRESS_ADD_FAIL",
}
export const POST_MEMBER_ADDRESS_REJECT_FAIL = {
    type: "POST_MEMBER_ADDRESS_REJECT_FAIL",
}
export const GET_MEMBER_ADDRESS_FAIL = {
    type: "GET_MEMBER_ADDRESS_FAIL",
}
export const GET_MEMBER_REJECTED_ADDRESS_FAIL = {
    type: "GET_MEMBER_REJECTED_ADDRESS_FAIL",
}

/**
 * NEW CONFIRM ADDRESS TYPES
 */
export const GET_CONFIRM_PREVIOUS_ADDRESSES = {
    type: "GET_CONFIRM_PREVIOUS_ADDRESSES",
}
export const POST_CONFIRM_ADDRESS_ADD = {
    type: "POST_CONFIRM_ADDRESS_ADD",
}
export const POST_CONFIRM_ADDRESS_REJECT = {
    type: "POST_CONFIRM_ADDRESS_REJECT",
}
export const POST_CONFIRM_ADDRESS_ACCEPT = {
    type: "POST_CONFIRM_ADDRESS_ACCEPT",
}
export const POST_CONFIRM_ADDRESS_ACCEPT_SUCCESS = {
    type: "POST_CONFIRM_ADDRESS_ACCEPT_SUCCESS",
}
export const POST_CONFIRM_ADDRESS_ACCEPT_FAIL = {
    type: "POST_CONFIRM_ADDRESS_ACCEPT_FAIL",
}
export const GET_CONFIRM_ADDRESS = { type: "GET_CONFIRM_ADDRESS" }
export const GET_CONFIRM_REJECTED_ADDRESS = {
    type: "GET_CONFIRM_REJECTED_ADDRESS",
}
export const GET_CONFIRM_PREVIOUS_ADDRESSES_SUCCESS = {
    type: "GET_CONFIRM_PREVIOUS_ADDRESSES_SUCCESS",
}
export const POST_CONFIRM_ADDRESS_ADD_SUCCESS = {
    type: "POST_CONFIRM_ADDRESS_ADD_SUCCESS",
}
export const POST_CONFIRM_ADDRESS_REJECT_SUCCESS = {
    type: "POST_CONFIRM_ADDRESS_REJECT_SUCCESS",
}
export const GET_CONFIRM_ADDRESS_SUCCESS = {
    type: "GET_CONFIRM_ADDRESS_SUCCESS",
}
export const GET_CONFIRM_REJECTED_ADDRESS_SUCCESS = {
    type: "GET_CONFIRM_REJECTED_ADDRESS_SUCCESS",
}
export const GET_CONFIRM_PREVIOUS_ADDRESSES_FAIL = {
    type: "GET_CONFIRM_PREVIOUS_ADDRESSES_FAIL",
}
export const POST_CONFIRM_ADDRESS_ADD_FAIL = {
    type: "POST_CONFIRM_ADDRESS_ADD_FAIL",
}
export const POST_CONFIRM_ADDRESS_REJECT_FAIL = {
    type: "POST_CONFIRM_ADDRESS_REJECT_FAIL",
}
export const GET_CONFIRM_ADDRESS_FAIL = {
    type: "GET_CONFIRM_ADDRESS_FAIL",
}
export const GET_CONFIRM_REJECTED_ADDRESS_FAIL = {
    type: "GET_CONFIRM_REJECTED_ADDRESS_FAIL",
}
