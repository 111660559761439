import {
    ASSETS_SEARCH_RESULTS_CLAIMED,
    ASSETS_SEARCH_RESULTS_SUMMARY,
    CLEAR_API_FAIL,
    GET_COUNTRIES,
    GET_FEEDBACK,
    GET_PARTNERS,
    GET_SEARCH_DETAILS,
    GET_SEARCH_DETAILS_MESSAGING,
    KYC_COMPLETE,
    KYC_INIT,
    LOGIN,
    LOGIN_REFRESH,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    MEMBER_ASSETS_CLAIM_RESULT,
    MEMBER_ASSETS_DONATE_RESULT,
    MEMBER_ASSETS_EMAIL_CLAIM_RESULT,
    MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS,
    MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_PERSON,
    MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON,
    MEMBER_ASSETS_UNCLAIM_RESULT, MEMBER_ASSETS_UNDONATE_RESULT,
    MEMBER_CHECK_PASSWORD_RESET_TOKEN,
    MEMBER_LOST_PASSWORD,
    MEMBER_PASSWORD_RESET_BY_MANUAL_CODE,
    MEMBER_PASSWORD_RESET_BY_TOKEN,
    MEMBER_UPDATE_FIRSTNAME,
    MEMBER_VERIFY_CHANGE_EMAIL,
    REFER_PARTNER,
    RESET_SEARCH_DETAILS,
    SCHEDULE_A_SEARCH,
    SCHEDULE_A_SEARCH_RESULTS,
    SCHEDULE_A_SEARCH_STATUS,
    SET_API_FAIL,
    SUBMIT_FEEDBACK,
    UPDATE_NOTIFICATION,
} from "~actions/actionTypes"
import {
    ASSETS_SEARCH_EMAIL_RESULT,
    BEGIN_KYC_INIT,
    CHECK_PASSWORD_RESET_TOKEN,
    CLAIM_RESULT,
    GET_ADDRESS_BY_POSTCODE,
    GET_ASSETS_SEARCH_RESULTS_SUMMARY,
    KYC_CONFIRM,
    LOST_PASSWORD_REQUEST,
    MEMBERS_CONFIRM_ACCEPT_ADDRESS,
    MEMBERS_CONFIRM_ADD_ADDRESS,
    MEMBERS_CONFIRM_PREVIOUS_ADDRESSES,
    MEMBERS_CONFIRM_REJECT_ADDRESS,
    MEMBERS_MEMBER_ADDRESS_DELETE,
    MEMBERS_MEMBER_ADDRESS_EDIT,
    MEMBERS_MEMBER_PREVIOUS_ADDRESSES,
    MEMBERS_MEMBER_ADDRESSES,
    MEMBERS_REGISTER_MIDDLE_NAMES,
    MEMBERS_REGISTER_PREFERRED_NAME,
    MEMBERS_REGISTER_VERIFY_CAPTCHA,
    MEMBER_LOGIN,
    MEMBER_ME,
    MEMBER_REFRESH,
    PASSWORD_RESET_BY_MANUAL_CODE,
    PASSWORD_RESET_BY_TOKEN,
    REGISTER_COMPLETE,
    REGISTER_INIT,
    REGISTER_ME,
    REGISTER_REFRESH,
    REGISTER_VERIFY_BY_MANUAL_CODE,
    REGISTER_VERIFY_BY_TOKEN,
    REGISTER_VERIFY_RESEND,
    REGISTRATION_COUNTRIES,
    SCHEDULE_SEARCH,
    SEARCH_RESULTS,
    SEARCH_RESULT_DETAILS,
    SEARCH_STATUS,
    UNCLAIM_RESULT,
    UPDATE_ADDRESS,
    UPDATE_DATEOFBIRTH,
    UPDATE_EMAIL,
    UPDATE_FIRSTNAME,
    UPDATE_LASTNAME,
    UPDATE_PASSWORD,
    UPDATE_PHONENUMBER,
    VERIFY_MEMBER_UPDATE_EMAIL,
    MEMBERS_ACCOUNT_FIRST_NAME_EDIT,
    MEMBERS_ACCOUNT_MIDDLE_NAMES_EDIT,
    MEMBERS_ACCOUNT_LAST_NAME_EDIT,
    MEMBERS_ACCOUNT_PREFERRED_NAME_EDIT,
    MEMBERS_ACCOUNT_DATE_OF_BIRTH_EDIT,
    MEMBERS_ACCOUNT_PHONE_NUMBER_EDIT,
    MEMBERS_ACCOUNT_EMAIL_CHANGE_REQUEST,
    MEMBERS_ADDRESS_REVIEWED,
    MEMBERS_ACCOUNT_PERSONAL_DETAILS,
    MEMBERS_MEMBER_RECOVER_OPTIONS,
    MEMBERS_MEMBER_RECOVER_SEND_SMS,
    MEMBERS_NAME_NAME_GET,
    SEARCH_RESULTS_CLAIMED,
    MEMBERS_MEMBER_RECOVER_SMS,
    MEMBERS_ACCOUNT_VERIFY_EMAIL_BY_CODE_EDIT,
    MEMBERS_ACCOUNT_NAME_DOWNLOAD_DOCUMENT,
    MEMBERS_NAME_TYPES_GET,
    MEMBERS_NAME_PREVIOUS_NAME_ADD,
    MEMBERS_ACCOUNT_NAME_DOCUMENT_UPLOAD,
    MEMBERS_NAME_PREVIOUS_NAME_DELETE,
    MEMBERS_NAME_PREVIOUS_NAME_GET,
    MEMBERS_MEMBER_ADDRESSES_ADD_NEW,
    MEMBERS_MEMBER_ADDRESSES_EDIT,
    MEMBERS_ACCOUNT_VERIFY_EMAIL_EDIT,
    MEMBERS_CONFIRM_BANK_INITIALISE,
    MEMBERS_CONFIRM_BANK_CALLBACK,
    MEMBERS_CONFIRM_BANK_SCHEDULE,
    MEMBERS_CONFIRM_BANK_STATUS,
    SEARCH_RESULT_DETAILS_MESSAGING,
    MEMBERS_ACCOUNT_PROFILE_NI_NUMBER,
    MEMBERS_ACCOUNT_PROFILE_CONFIRM_ADDRESSES,
    MEMBERS_ACCOUNT_PROFILE_CONFIRM_NAMES,
    MEMBERS_REGISTER_VALIDATE,
    MEMBERS_SEARCH_RESULTS_CONNECTED,
    MEMBERS_SEARCH_RESULTS_CONNECTED_PERSON,
    MEMBERS_ACCOUNT_DELETE,
    MEMBERS_GET_PARTNERS,
    MEMBERS_REFER_PARTNER,
    MEMBERS_GET_NOTIFICATIONS,
    MEMBERS_ACCOUNT_UPDATE_MARKETING_PREFERENCES,
    MEMBERS_SEARCH_RESULTS_NONCURRENT_PERSON,
    GET_ASSETS_SEARCH_RESULTS_DEFAULT_SUMMARY,
    MEMBERS_THIRD_PARTY_ADD_EDIT_NATIONAL_INSURANCE,
    PRE_REGISTER_INIT,
    MEMBERS_NAME_PREVIOUS_NAME_EDIT,
    GET_CURRENT_FEEDBACK,
    POST_FEEDBACK,
    DONATE_RESULT, 
    UNDONATE_RESULT,
} from "~helpers/endpoints"
import {
    transformCheckPasswordResetToken,
    transformEditAddressData,
    transformForgotPasswordReset,
    transformGenericPatchData,
    transformKycAnswerData,
    transformManualCode,
    transformObjectData,
    transformResend,
    transformTokenPostData,
} from "~helpers/helpers"
import { GET_ADDRESSES_BY_POSTCODE } from "./addressTypes"
import {
    GET_CONFIRM_PREVIOUS_ADDRESSES,
    GET_MEMBER_PREVIOUS_ADDRESSES,
    POST_CONFIRM_ADDRESS_ACCEPT,
    POST_CONFIRM_ADDRESS_ADD,
    POST_CONFIRM_ADDRESS_REJECT,
    POST_MEMBER_ADDRESS_ADD,
    POST_MEMBER_ADDRESS_REJECT,
} from "./historicAddressTypes"
import {
    MEMBER_REQUEST,
    MEMBER_SET_STEP,
    MEMBER_UPDATE_ADDRESS,
    MEMBER_UPDATE_DATEOFBIRTH,
    MEMBER_UPDATE_EMAIL,
    MEMBER_UPDATE_LASTNAME,
    MEMBER_UPDATE_PASSWORD,
    MEMBER_UPDATE_PHONE,
    MEMBER_UPDATE_SEARCH_REQUIRED,
    MEMBER_UPDATE_MIDDLE_NAMES,
    MEMBER_UPDATE_PREFERRED_NAME,
    SET_MEMBER,
    MEMBER_CONFIRMED_REVIEWED_ADDRESSES,
    MEMBER_CONFIRM_BANK_INITIALISE,
    MEMBER_CONFIRM_BANK_CALLBACK,
    MEMBER_CONFIRM_BANK_SCHEDULE,
    MEMBER_CONFIRM_BANK_STATUS,
    MEMBER_ACCOUNT_UPDATE_MARKETING_PREFERENCES,
} from "./memberTypes"
import {
    PRE_REGISTRATION,
    REGISTRATION_COMPLETE,
    REGISTRATION_INIT,
    REGISTRATION_ME,
    REGISTRATION_MEMBER_VALIDATE,
    REGISTRATION_REFRESH,
    REGISTRATION_VERIFY_BY_CODE,
    REGISTRATION_VERIFY_BY_TOKEN,
    REGISTRATION_VERIFY_CAPTCHA,
    REGISTRATION_VERIFY_RESEND,
} from "./registrationTypes"
import * as accountTypes from "./accountTypes"
import { MEMBERS_ACCOUNT_PASSWORD_EDIT } from "~helpers/endpoints"
import { GET_NOTIFICATIONS } from "./notificationTypes"
import { THIRD_PARTY_NI_NUMBER_UPDATE } from "./thirdPartyTypes"

function requestLogout() {
    return {
        type: LOGOUT_REQUEST.type,
        isFetching: true,
        isAuthenticated: false,
    }
}
export function setMember(data) {
    return {
        type: SET_MEMBER.type,
        data: data,
    }
}

export function setMemberSearchRequired(data) {
    return {
        type: MEMBER_UPDATE_SEARCH_REQUIRED.type,
        data: data,
    }
}

function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS.type,
        isFetching: false,
        isAuthenticated: false,
    }
}
export function setApiErrors(errors) {
    return {
        type: SET_API_FAIL.type,
        errors,
    }
}
export function clearApiErrors() {
    return {
        type: CLEAR_API_FAIL.type,
    }
}
export function setMemberRegistrationStep(step) {
    return {
        type: MEMBER_SET_STEP.type,
        currentStep: step,
    }
}

// Logs the user out
export function logoutUser() {
    return dispatch => {
        dispatch(requestLogout())
        dispatch(receiveLogout())
        dispatch(clearApiErrors())
    }
}
export function purgeBeforeRegistration() {
    return dispatch => {
        dispatch(logoutUser())
        dispatch({ type: "PURGE_REGISTRATION" })
    }
}
export function registrationInit(affiliate = false, externalReference = false) {
    return {
        type: REGISTRATION_INIT.type,
        payload: {
            request: {
                url: REGISTER_INIT.url,
                method: REGISTER_INIT.method,
                params: {
                    ...(affiliate && { affiliate: affiliate }),
                    ...(externalReference && {
                        externalReference: externalReference,
                    }),
                },
            },
        },
    }
}
export function verifyEmailByManualCode(data) {
    return {
        type: REGISTRATION_VERIFY_BY_CODE.type,
        payload: {
            request: {
                url: REGISTER_VERIFY_BY_MANUAL_CODE.url,
                method: REGISTER_VERIFY_BY_MANUAL_CODE.method,
                data: transformManualCode("member", data),
                params: {
                    username: data.username,
                },
            },
        },
    }
}
export function requestCountries() {
    return {
        type: GET_COUNTRIES.type,
        payload: {
            request: {
                url: REGISTRATION_COUNTRIES.url,
                method: REGISTRATION_COUNTRIES.method,
            },
        },
    }
}
export function beginKyc() {
    return {
        type: KYC_INIT.type,
        payload: {
            request: {
                url: BEGIN_KYC_INIT.url,
                method: BEGIN_KYC_INIT.method,
            },
        },
    }
}
export function completeKyc(data, sessionId) {
    return {
        type: KYC_COMPLETE.type,
        payload: {
            request: {
                url: KYC_CONFIRM.url,
                method: KYC_CONFIRM.method,
                data: transformKycAnswerData("session", data, sessionId),
            },
        },
    }
}
export function scheduleSearch() {
    return {
        type: SCHEDULE_A_SEARCH.type,
        payload: {
            request: {
                url: SCHEDULE_SEARCH.url,
                method: SCHEDULE_SEARCH.method,
            },
        },
    }
}
export function getSearchStatus(searchOperation) {
    return {
        type: SCHEDULE_A_SEARCH_STATUS.type,
        payload: {
            request: {
                url: SEARCH_STATUS.url,
                method: SEARCH_STATUS.method,
                params: {
                    searchOperation: searchOperation,
                },
            },
        },
    }
}
export function getSearchStatusResults(searchOperation) {
    return {
        type: SCHEDULE_A_SEARCH_RESULTS.type,
        payload: {
            request: {
                url: SEARCH_RESULTS.url,
                method: SEARCH_RESULTS.method,
                params: {
                    searchOperation: searchOperation,
                },
            },
        },
    }
}

export function requestMember(
    recommendAction = false,
    flagIfHasMatches = false
) {
    return {
        type: MEMBER_REQUEST.type,
        payload: {
            request: {
                url: MEMBER_ME.url,
                method: MEMBER_ME.method,
                params: {
                    include: "address",
                    ...(recommendAction && { recommendAction: true }),
                    ...(flagIfHasMatches && { flagIfHasMatches: true }),
                },
            },
        },
    }
}

export function resendEmail(data) {
    return {
        type: REGISTRATION_VERIFY_RESEND.type,
        payload: {
            request: {
                url: REGISTER_VERIFY_RESEND.url,
                method: REGISTER_VERIFY_RESEND.method,
                data: transformResend("member", data),
                params: {
                    username: data,
                },
            },
        },
    }
}
export function verifyMemberChangeEmail(data) {
    return {
        type: MEMBER_VERIFY_CHANGE_EMAIL.type,
        payload: {
            request: {
                url: VERIFY_MEMBER_UPDATE_EMAIL.url,
                method: VERIFY_MEMBER_UPDATE_EMAIL.method,
                data: transformGenericPatchData("member", data.username),
            },
        },
    }
}

export function lostPasswordRequest(data) {
    return {
        type: MEMBER_LOST_PASSWORD.type,
        payload: {
            request: {
                url: LOST_PASSWORD_REQUEST.url,
                method: LOST_PASSWORD_REQUEST.method,
                data: transformResend("member", data),
                params: {
                    username: data,
                },
            },
        },
    }
}
export function checkPasswordResetToken(data) {
    return {
        type: MEMBER_CHECK_PASSWORD_RESET_TOKEN.type,
        payload: {
            request: {
                url: CHECK_PASSWORD_RESET_TOKEN.url,
                method: CHECK_PASSWORD_RESET_TOKEN.method,
                data: transformCheckPasswordResetToken("member", data),
            },
        },
    }
}
export function passwordResetByToken(data) {
    return {
        type: MEMBER_PASSWORD_RESET_BY_TOKEN.type,
        payload: {
            request: {
                url: PASSWORD_RESET_BY_TOKEN.url,
                method: PASSWORD_RESET_BY_TOKEN.method,
                data: transformForgotPasswordReset("member", data),
            },
        },
    }
}
export function passwordResetByManualCode(data) {
    return {
        type: MEMBER_PASSWORD_RESET_BY_MANUAL_CODE.type,
        payload: {
            request: {
                url: PASSWORD_RESET_BY_MANUAL_CODE.url,
                method: PASSWORD_RESET_BY_MANUAL_CODE.method,
                data: transformForgotPasswordReset("member", data),
                params: {
                    username: data.username,
                },
            },
        },
    }
}
export function registrationRefresh() {
    return {
        type: REGISTRATION_REFRESH.type,
        payload: {
            request: {
                url: REGISTER_REFRESH.url,
                method: REGISTER_REFRESH.method,
            },
        },
    }
}
export function registrationMe() {
    return {
        type: REGISTRATION_ME.type,
        payload: {
            request: {
                url: REGISTER_ME.url,
                method: REGISTER_ME.method,
            },
        },
    }
}
export function memberRefresh() {
    return {
        type: LOGIN_REFRESH.type,
        payload: {
            request: {
                url: MEMBER_REFRESH.url,
                method: MEMBER_REFRESH.method,
            },
        },
    }
}

export function loginUser(creds, scheduleSearch = false) {
    return {
        type: LOGIN.type,
        payload: {
            request: {
                url: MEMBER_LOGIN.url,
                method: MEMBER_LOGIN.method,
                data: transformObjectData("member", creds),
                params: {
                    username: creds.username,
                    ...(scheduleSearch && { scheduleSearch: true }),
                },
            },
        },
    }
}
export function verifyEmailByToken(token) {
    return {
        type: REGISTRATION_VERIFY_BY_TOKEN.type,
        payload: {
            request: {
                url: REGISTER_VERIFY_BY_TOKEN.url,
                method: REGISTER_VERIFY_BY_TOKEN.method,
                data: transformTokenPostData("member", token),
            },
        },
    }
}

export function updateUserFirstName(firstName) {
    return {
        type: MEMBER_UPDATE_FIRSTNAME.type,
        payload: {
            request: {
                url: UPDATE_FIRSTNAME.url,
                method: UPDATE_FIRSTNAME.method,
                data: transformGenericPatchData("member", firstName),
            },
        },
    }
}

export function updateUserLastName(lastName) {
    return {
        type: MEMBER_UPDATE_LASTNAME.type,
        payload: {
            request: {
                url: UPDATE_LASTNAME.url,
                method: UPDATE_LASTNAME.method,
                data: transformGenericPatchData("member", lastName),
            },
        },
    }
}
export function updateUserMiddleNames(middleNames) {
    return {
        type: MEMBER_UPDATE_MIDDLE_NAMES.type,
        payload: {
            request: {
                url: MEMBERS_REGISTER_MIDDLE_NAMES.url,
                method: MEMBERS_REGISTER_MIDDLE_NAMES.method,
                data: transformGenericPatchData("member", middleNames),
            },
        },
    }
}
export function updateUserPreferredName(preferredName) {
    return {
        type: MEMBER_UPDATE_PREFERRED_NAME.type,
        payload: {
            request: {
                url: MEMBERS_REGISTER_PREFERRED_NAME.url,
                method: MEMBERS_REGISTER_PREFERRED_NAME.method,
                data: transformGenericPatchData("member", preferredName),
            },
        },
    }
}
export function updateUserEmail(email) {
    return {
        type: MEMBER_UPDATE_EMAIL.type,
        payload: {
            request: {
                url: UPDATE_EMAIL.url,
                method: UPDATE_EMAIL.method,
                data: transformGenericPatchData("member", email),
            },
        },
    }
}
export function updateUserDateOfBirth(dob) {
    return {
        type: MEMBER_UPDATE_DATEOFBIRTH.type,
        payload: {
            request: {
                url: UPDATE_DATEOFBIRTH.url,
                method: UPDATE_DATEOFBIRTH.method,
                data: transformGenericPatchData("member", dob),
            },
        },
    }
}
export function updateUserPhone(phone) {
    return {
        type: MEMBER_UPDATE_PHONE.type,
        payload: {
            request: {
                url: UPDATE_PHONENUMBER.url,
                method: UPDATE_PHONENUMBER.method,
                data: transformGenericPatchData("member", phone),
            },
        },
    }
}
export function updateUserAddress(address) {
    return {
        type: MEMBER_UPDATE_ADDRESS.type,
        payload: {
            request: {
                url: UPDATE_ADDRESS.url,
                method: UPDATE_ADDRESS.method,
                data: transformObjectData("address", address),
            },
        },
    }
}
export function updateUserPassword(password) {
    return {
        type: MEMBER_UPDATE_PASSWORD.type,
        payload: {
            request: {
                url: UPDATE_PASSWORD.url,
                method: UPDATE_PASSWORD.method,
                data: transformGenericPatchData("member", password),
            },
        },
    }
}
export function registrationComplete() {
    return {
        type: REGISTRATION_COMPLETE.type,
        payload: {
            request: {
                url: REGISTER_COMPLETE.url,
                method: REGISTER_COMPLETE.method,
                data: transformGenericPatchData("member", ""),
            },
        },
    }
}
export function getAddressesByPostcode(value) {
    return {
        type: GET_ADDRESSES_BY_POSTCODE.type,
        payload: {
            request: {
                url: GET_ADDRESS_BY_POSTCODE.url,
                method: GET_ADDRESS_BY_POSTCODE.method,
                params: { postcode: value },
            },
        },
    }
}
export function getMatchDetails(matchId) {
    return {
        type: GET_SEARCH_DETAILS.type,
        payload: {
            request: {
                url: SEARCH_RESULT_DETAILS.url + matchId,
                method: SEARCH_RESULT_DETAILS.method,
            },
        },
    }
}

/**
 * PREVIOUS ADDRESS FUNCTIONS
 */
export function getPreviousAddresses() {
    return {
        type: GET_CONFIRM_PREVIOUS_ADDRESSES.type,
        payload: {
            request: {
                url: MEMBERS_CONFIRM_PREVIOUS_ADDRESSES.url,
                method: MEMBERS_CONFIRM_PREVIOUS_ADDRESSES.method,
            },
        },
    }
}
export function addNewHistoricAddress(address) {
    return {
        type: POST_CONFIRM_ADDRESS_ADD.type,
        payload: {
            request: {
                url: MEMBERS_CONFIRM_ADD_ADDRESS.url,
                method: MEMBERS_CONFIRM_ADD_ADDRESS.method,
                data: transformObjectData("address", address),
            },
        },
    }
}

export function rejectNewHistoricAddress(address) {
    return {
        type: POST_CONFIRM_ADDRESS_REJECT.type,
        payload: {
            request: {
                url: MEMBERS_CONFIRM_REJECT_ADDRESS.url + address,
                method: MEMBERS_CONFIRM_REJECT_ADDRESS.method,
            },
        },
    }
}
export function acceptNewHistoricAddress(address) {
    return {
        type: POST_CONFIRM_ADDRESS_ACCEPT.type,
        payload: {
            request: {
                url: MEMBERS_CONFIRM_ACCEPT_ADDRESS.url + address,
                method: MEMBERS_CONFIRM_ACCEPT_ADDRESS.method,
            },
        },
    }
}
export function emailClaimDetails(matchId) {
    return {
        type: MEMBER_ASSETS_EMAIL_CLAIM_RESULT.type,
        payload: {
            request: {
                url: ASSETS_SEARCH_EMAIL_RESULT.url + matchId,
                method: ASSETS_SEARCH_EMAIL_RESULT.method,
            },
        },
    }
}
export function acceptClaim(matchId) {
    return {
        type: MEMBER_ASSETS_CLAIM_RESULT.type,
        payload: {
            request: {
                url: CLAIM_RESULT.url + matchId,
                method: CLAIM_RESULT.method,
            },
        },
    }
}
export function rejectClaim(matchId) {
    return {
        type: MEMBER_ASSETS_UNCLAIM_RESULT.type,
        payload: {
            request: {
                url: UNCLAIM_RESULT.url + matchId,
                method: UNCLAIM_RESULT.method,
            },
        },
    }
}
export function donateMatch(matchId) {
    return {
        type: MEMBER_ASSETS_DONATE_RESULT.type,
        payload: {
            request: {
                url: DONATE_RESULT.url + matchId,
                method: DONATE_RESULT.method,
            },
        },
    }
}
export function undonateMatch(matchId) {
    return {
        type: MEMBER_ASSETS_UNDONATE_RESULT.type,
        payload: {
            request: {
                url: UNDONATE_RESULT.url + matchId,
                method: UNDONATE_RESULT.method,
            },
        },
    }
}

export function resetSearchDetail() {
    return {
        type: RESET_SEARCH_DETAILS.type,
    }
}

export function getMemberAddresses() {
    return {
        type: GET_MEMBER_PREVIOUS_ADDRESSES.type,
        payload: {
            request: {
                url: MEMBERS_MEMBER_ADDRESSES.url,
                method: MEMBERS_MEMBER_ADDRESSES.method,
            },
        },
    }
}
/**
 * PREVIOUS MEMBER ADDRESS FUNCTIONS
 */
export function getMemberPreviousAddresses() {
    return {
        type: GET_MEMBER_PREVIOUS_ADDRESSES.type,
        payload: {
            request: {
                url: MEMBERS_MEMBER_PREVIOUS_ADDRESSES.url,
                method: MEMBERS_MEMBER_PREVIOUS_ADDRESSES.method,
            },
        },
    }
}
export function addMemberNewHistoricAddress(addressIndex, address) {
    return {
        type: POST_MEMBER_ADDRESS_ADD.type,
        payload: {
            request: {
                url: MEMBERS_MEMBER_ADDRESS_EDIT.url + addressIndex,
                method: MEMBERS_MEMBER_ADDRESS_EDIT.method,
                data: transformEditAddressData(
                    "address",
                    address,
                    addressIndex
                ),
            },
        },
    }
}

export function rejectMemberNewHistoricAddress(address) {
    return {
        type: POST_MEMBER_ADDRESS_REJECT.type,
        payload: {
            request: {
                url: MEMBERS_MEMBER_ADDRESS_DELETE.url + address,
                method: MEMBERS_MEMBER_ADDRESS_DELETE.method,
            },
        },
    }
}

export function memberAssetsSearchSummary() {
    return {
        type: ASSETS_SEARCH_RESULTS_SUMMARY.type,
        payload: {
            request: {
                url: GET_ASSETS_SEARCH_RESULTS_SUMMARY.url,
                method: GET_ASSETS_SEARCH_RESULTS_SUMMARY.method,
            },
        },
    }
}
export function memberAssetsSearchDefaultSummary() {
    return {
        type: ASSETS_SEARCH_RESULTS_SUMMARY.type,
        payload: {
            request: {
                url: GET_ASSETS_SEARCH_RESULTS_DEFAULT_SUMMARY.url,
                method: GET_ASSETS_SEARCH_RESULTS_DEFAULT_SUMMARY.method,
            },
        },
    }
}
export function registrationVerifyCaptcha(value) {
    return {
        type: REGISTRATION_VERIFY_CAPTCHA.type,
        payload: {
            request: {
                url: MEMBERS_REGISTER_VERIFY_CAPTCHA.url,
                method: MEMBERS_REGISTER_VERIFY_CAPTCHA.method,
                data: transformObjectData("member", { value: value }),
            },
        },
    }
}

/**
 * My Account actions
 */

export function membersAccountFirstNameEdit(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_FIRST_NAME_EDIT.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_FIRST_NAME_EDIT,
                method: MEMBERS_ACCOUNT_FIRST_NAME_EDIT.method,
                data: transformObjectData("member", { value: value }),
            },
        },
    }
}
export function membersAccountMiddleNamesEdit(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_MIDDLE_NAMES_EDIT.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_MIDDLE_NAMES_EDIT,
                method: MEMBERS_ACCOUNT_MIDDLE_NAMES_EDIT.method,
                data: transformObjectData("member", { value: value }),
            },
        },
    }
}
export function membersAccountLastNameEdit(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_LAST_NAME_EDIT.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_LAST_NAME_EDIT,
                method: MEMBERS_ACCOUNT_LAST_NAME_EDIT.method,
                data: transformObjectData("member", { value: value }),
            },
        },
    }
}
export function membersAccountPreferredNameEdit(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_PREFERRED_NAME_EDIT.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_PREFERRED_NAME_EDIT,
                method: MEMBERS_ACCOUNT_PREFERRED_NAME_EDIT.method,
                data: transformObjectData("member", { value: value }),
            },
        },
    }
}
export function membersAccountDateOfBirthEdit(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_DATE_OF_BIRTH_EDIT.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_DATE_OF_BIRTH_EDIT,
                method: MEMBERS_ACCOUNT_DATE_OF_BIRTH_EDIT.method,
                data: transformObjectData("member", { value: value }),
            },
        },
    }
}
export function membersAccountPhoneNumberEdit(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_PHONE_NUMBER_EDIT.url,
                method: MEMBERS_ACCOUNT_PHONE_NUMBER_EDIT.method,
                data: transformObjectData("member", { value: value }),
            },
        },
    }
}
export function membersAccountEmailEdit(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_EMAIL_UPDATE.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_EMAIL_CHANGE_REQUEST.url,
                method: MEMBERS_ACCOUNT_EMAIL_CHANGE_REQUEST.method,
                data: transformObjectData("member", value),
            },
        },
    }
}

export function confirmedMembersAddressReviewed() {
    return {
        type: MEMBER_CONFIRMED_REVIEWED_ADDRESSES.type,
        payload: {
            request: {
                url: MEMBERS_ADDRESS_REVIEWED.url,
                method: MEMBERS_ADDRESS_REVIEWED.method,
            },
        },
    }
}

export function membersAccountUpdatePersonalDetails(values) {
    return {
        type: accountTypes.MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_PERSONAL_DETAILS.url,
                method: MEMBERS_ACCOUNT_PERSONAL_DETAILS.method,
                data: transformObjectData("member", { ...values }),
            },
        },
    }
}
export function membersAccountUpdateUserPassword(values) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_PASSWORD_UPDATE.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_PASSWORD_EDIT.url,
                method: MEMBERS_ACCOUNT_PASSWORD_EDIT.method,
                data: transformObjectData("member", { ...values }),
            },
        },
    }
}

export function membersAccountGetRecoverOptions(values) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_RECOVER_OPTIONS.type,
        payload: {
            request: {
                url: MEMBERS_MEMBER_RECOVER_OPTIONS.url,
                method: MEMBERS_MEMBER_RECOVER_OPTIONS.method,
                data: transformObjectData("member", { ...values }),
            },
        },
    }
}

export function membersAccountRecoverySendSMS(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_RECOVERY_SEND_SMS.type,
        payload: {
            request: {
                url: MEMBERS_MEMBER_RECOVER_SEND_SMS.url,
                method: MEMBERS_MEMBER_RECOVER_SEND_SMS.method,
                data: {
                    data: {
                        id: value,
                        type: "member",
                    },
                },
            },
        },
    }
}

export function membersAccountRecoverBySMS(values) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_RECOVERY_BY_SMS.type,
        payload: {
            request: {
                url: MEMBERS_MEMBER_RECOVER_SMS.url,
                method: MEMBERS_MEMBER_RECOVER_SMS.method,
                data: transformObjectData("member", { ...values }),
            },
        },
    }
}

export function membersNamesGetNames() {
    return {
        type: accountTypes.MEMBERS_NAMES_GET_NAMES.type,
        payload: {
            request: {
                url: MEMBERS_NAME_NAME_GET.url,
                method: MEMBERS_NAME_NAME_GET.method,
            },
        },
    }
}

export function getClaimedAssetSearchResults() {
    return {
        type: ASSETS_SEARCH_RESULTS_CLAIMED.type,
        payload: {
            request: {
                url: SEARCH_RESULTS_CLAIMED.url,
                method: SEARCH_RESULTS_CLAIMED.method,
            },
        },
    }
}

export function memberAccountNameDownloadDocument(previousNameId) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_PREVIOUS_NAME_DOWNLOAD_DOCUMENT.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_NAME_DOWNLOAD_DOCUMENT(previousNameId).url,
                method: MEMBERS_ACCOUNT_NAME_DOWNLOAD_DOCUMENT(previousNameId)
                    .method,
            },
        },
    }
}
export function memberAccountNamesGetTypesForDocument() {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_GET_NAME_TYPES.type,
        payload: {
            request: {
                url: MEMBERS_NAME_TYPES_GET.url,
                method: MEMBERS_NAME_TYPES_GET.method,
            },
        },
    }
}
export function memberAccountsAddPreviousName(values) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME.type,
        payload: {
            request: {
                url: MEMBERS_NAME_PREVIOUS_NAME_ADD.url,
                method: MEMBERS_NAME_PREVIOUS_NAME_ADD.method,
                data: transformObjectData("previousName", {
                    ...values,
                }),
                params: {
                    ...(values.replacement && { replaceCurrent: true }),
                },
            },
        },
    }
}
export function memberAccountsEditPreviousName(id, values) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_EDIT_PREVIOUS_NAME.type,
        payload: {
            request: {
                url: MEMBERS_NAME_PREVIOUS_NAME_EDIT.url + `/${id}`,
                method: MEMBERS_NAME_PREVIOUS_NAME_EDIT.method,
                data: transformObjectData("previousName", {
                    ...values,
                }),
                params: {
                    ...(values.replacement && { replaceCurrent: true }),
                },
            },
        },
    }
}

export function memberAccountsPreviousNameAddNewDocument(values) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_UPLOAD_DOCUMENT
            .type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_NAME_DOCUMENT_UPLOAD.url,
                method: MEMBERS_ACCOUNT_NAME_DOCUMENT_UPLOAD.method,
                data: transformObjectData("document", {
                    ...values,
                }),
            },
        },
    }
}

export function memberAccountsDeletePreviousName(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_DELETE_PREVIOUS_NAME.type,
        payload: {
            request: {
                url: MEMBERS_NAME_PREVIOUS_NAME_DELETE.url + value,
                method: MEMBERS_NAME_PREVIOUS_NAME_DELETE.method,
            },
        },
    }
}

export function memberAccountGetPreviousNameById(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_PREVIOUS_NAME_VIEW.type,
        payload: {
            request: {
                url: MEMBERS_NAME_PREVIOUS_NAME_GET(value).url,
                method: MEMBERS_NAME_PREVIOUS_NAME_GET(value).method,
                params: {
                    requestUploadToken: true,
                },
            },
        },
    }
}

export function memberAccountAddNewAddress(values) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_ADD_NEW_ADDRESS.type,
        payload: {
            request: {
                url: MEMBERS_MEMBER_ADDRESSES_ADD_NEW.url,
                method: MEMBERS_MEMBER_ADDRESSES_ADD_NEW.method,
                data: transformObjectData("address", {
                    ...values,
                }),
                params: {
                    ...(values.replaceCurrent && { replaceCurrent: true }),
                },
            },
        },
    }
}

export function memberAccountEditAddress(editAddress) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_EDIT_ADDRESS.type,
        payload: {
            request: {
                url: MEMBERS_MEMBER_ADDRESSES_EDIT(editAddress.id).url,
                method: MEMBERS_MEMBER_ADDRESSES_EDIT(editAddress.id).method,
                data: transformObjectData("address", {
                    ...editAddress.attributes,
                }),
                params: {
                    ...(editAddress.attributes.replaceCurrent && {
                        replaceCurrent: true,
                    }),
                },
            },
        },
    }
}

export function memberVerifyEmailByToken(token) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_VERIFY_BY_TOKEN.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_VERIFY_EMAIL_EDIT.url,
                method: MEMBERS_ACCOUNT_VERIFY_EMAIL_EDIT.method,
                data: transformTokenPostData("member", token),
            },
        },
    }
}
export function memberVerifyEmailChangeByManualCode(data) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_VERIFY_BY_MANUAL_CODE.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_VERIFY_EMAIL_BY_CODE_EDIT.url,
                method: MEMBERS_ACCOUNT_VERIFY_EMAIL_BY_CODE_EDIT.method,
                data: transformObjectData("member", data),
            },
        },
    }
}

export function memberConfirmBankInitialise() {
    return {
        type: MEMBER_CONFIRM_BANK_INITIALISE.type,
        payload: {
            request: {
                url: MEMBERS_CONFIRM_BANK_INITIALISE.url,
                method: MEMBERS_CONFIRM_BANK_INITIALISE.method,
            },
        },
    }
}
export function memberConfirmBankCallback(data) {
    return {
        type: MEMBER_CONFIRM_BANK_CALLBACK.type,
        payload: {
            request: {
                url: MEMBERS_CONFIRM_BANK_CALLBACK.url,
                method: MEMBERS_CONFIRM_BANK_CALLBACK.method,
                data: transformObjectData("callback", data),
            },
        },
    }
}
// transactionKey from url
export function memberConfirmBankSchedule(data) {
    return {
        type: MEMBER_CONFIRM_BANK_SCHEDULE.type,
        payload: {
            request: {
                url: MEMBERS_CONFIRM_BANK_SCHEDULE.url,
                method: MEMBERS_CONFIRM_BANK_SCHEDULE.method,
                data: transformObjectData("session", data),
            },
        },
    }
}

// IN_PROGRESS – the verification check is being performed but has not yet returned any results
// SUCCESS – the Member’s bank details were verified successfully and they have therefore confirmed their identity. They should use a new JWT token for authentication from now on
// FAILURE – the Member’s bank details were not considered a suitable match to their registered details and therefore their identity has not been confirmed
// ERROR – the verification check could not be completed due to a service error
// TIMED_OUT – the verification check has exceeded the configured time limit
export function memberConfirmBankStatus(verificationId) {
    return {
        type: MEMBER_CONFIRM_BANK_STATUS.type,
        payload: {
            request: {
                url: MEMBERS_CONFIRM_BANK_STATUS(verificationId).url,
                method: MEMBERS_CONFIRM_BANK_STATUS(verificationId).method,
            },
        },
    }
}

export function getMatchMessageDetails(matchId) {
    return {
        type: GET_SEARCH_DETAILS_MESSAGING.type,
        payload: {
            request: {
                url: SEARCH_RESULT_DETAILS_MESSAGING(matchId).url,
                method: SEARCH_RESULT_DETAILS_MESSAGING(matchId).method,
            },
        },
    }
}

export function dismissNotification(notificationId) {
    return {
        type: UPDATE_NOTIFICATION.type,
        payload: {
            notificationId: notificationId,
        },
    }
}
export function membersAccountNINumberEdit(value) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_NI_NUMBER_UPDATE.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_PROFILE_NI_NUMBER.url,
                method: MEMBERS_ACCOUNT_PROFILE_NI_NUMBER.method,
                data: transformObjectData("member", { value: value }),
            },
        },
    }
}

export function profileConfirmPreviousAddresses() {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_PROFILE_ADDRESS_CONFIRM.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_PROFILE_CONFIRM_ADDRESSES.url,
                method: MEMBERS_ACCOUNT_PROFILE_CONFIRM_ADDRESSES.method,
                data: transformObjectData("member", { value: true }),
            },
        },
    }
}
export function profileConfirmPreviousNames() {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_PROFILE_NAMES_CONFIRM.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_PROFILE_CONFIRM_NAMES.url,
                method: MEMBERS_ACCOUNT_PROFILE_CONFIRM_NAMES.method,
                data: transformObjectData("member", { value: true }),
            },
        },
    }
}
export function registrationMemberValidate(excluded) {
    return {
        type: REGISTRATION_MEMBER_VALIDATE.type,
        payload: {
            request: {
                url: MEMBERS_REGISTER_VALIDATE.url,
                method: MEMBERS_REGISTER_VALIDATE.method,
                params: {
                    ...(excluded && { excluded: excluded }),
                },
            },
        },
    }
}

// Connected results
export function memberAssetsSearchConnectedResults() {
    return {
        type: MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS.type,
        payload: {
            request: {
                url: MEMBERS_SEARCH_RESULTS_CONNECTED.url,
                method: MEMBERS_SEARCH_RESULTS_CONNECTED.method,
            },
        },
    }
}
export function memberAssetsSearchConnectedResultsByPerson(personId) {
    return {
        type: MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_PERSON.type,
        payload: {
            request: {
                url: MEMBERS_SEARCH_RESULTS_CONNECTED_PERSON(personId).url,
                method: MEMBERS_SEARCH_RESULTS_CONNECTED_PERSON(personId)
                    .method,
            },
        },
    }
}
export function memberAssetsSearchNonCurrentResultsByPerson(personId) {
    return {
        type: MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON.type,
        payload: {
            request: {
                url: MEMBERS_SEARCH_RESULTS_NONCURRENT_PERSON(personId).url,
                method: MEMBERS_SEARCH_RESULTS_NONCURRENT_PERSON(personId)
                    .method,
            },
        },
    }
}

export function memberAccountDelete(data) {
    return {
        type: accountTypes.MEMBERS_ACCOUNT_DELETE.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_DELETE.url,
                method: MEMBERS_ACCOUNT_DELETE.method,
                data: transformObjectData("member", data),
            },
        },
    }
}

export function getPartners(pagination) {
    return {
        type: GET_PARTNERS.type,
        payload: {
            request: {
                url: MEMBERS_GET_PARTNERS.url,
                method: MEMBERS_GET_PARTNERS.method,
                params: {
                    ...(pagination.number && {
                        "page.number": pagination.number,
                    }),
                    ...(pagination.size && {
                        "page.size": pagination.size,
                    }),
                    ...(pagination.nonce && {
                        nonce: pagination.nonce,
                    }),
                    ...(pagination.featured && {
                        featured: pagination.featured,
                    }),
                },
            },
        },
    }
}

export function memberReferPartner(partnerId) {
    return {
        type: REFER_PARTNER.type,
        payload: {
            request: {
                url: MEMBERS_REFER_PARTNER(partnerId).url,
                method: MEMBERS_REFER_PARTNER(partnerId).method,
            },
        },
    }
}
export function memberGetNotifications() {
    return {
        type: GET_NOTIFICATIONS.type,
        payload: {
            request: {
                url: MEMBERS_GET_NOTIFICATIONS.url,
                method: MEMBERS_GET_NOTIFICATIONS.method,
            },
        },
    }
}

export function memberAccountUpdateMarketingPreferences(newPreference) {
    return {
        type: MEMBER_ACCOUNT_UPDATE_MARKETING_PREFERENCES.type,
        payload: {
            request: {
                url: MEMBERS_ACCOUNT_UPDATE_MARKETING_PREFERENCES.url,
                method: MEMBERS_ACCOUNT_UPDATE_MARKETING_PREFERENCES.method,
                data: transformObjectData("member", { value: newPreference }),
            },
        },
    }
}

export function thirdPartyAccountNINumberEdit(thirdPartyId, value) {
    return {
        type: THIRD_PARTY_NI_NUMBER_UPDATE.type,
        payload: {
            request: {
                url: MEMBERS_THIRD_PARTY_ADD_EDIT_NATIONAL_INSURANCE(
                    thirdPartyId
                ).url,
                method: MEMBERS_THIRD_PARTY_ADD_EDIT_NATIONAL_INSURANCE(
                    thirdPartyId
                ).method,
                data: transformObjectData("thirdParty", { value: value }),
            },
        },
    }
}

export function preRegistration(data) {
    return {
        type: PRE_REGISTRATION.type,
        payload: {
            request: {
                url: PRE_REGISTER_INIT.url,
                method: PRE_REGISTER_INIT.method,
                data: transformObjectData("member", data),
            },
        },
    }
}

export function getFeedback() {
    return {
        type: GET_FEEDBACK.type,
        payload: {
            request: {
                url: GET_CURRENT_FEEDBACK.url,
                method: GET_CURRENT_FEEDBACK.method,
            },
        },
    }
}
export function submitFeedback(data) {
    return {
        type: SUBMIT_FEEDBACK.type,
        payload: {
            request: {
                url: POST_FEEDBACK.url,
                method: POST_FEEDBACK.method,
                params: {
                    credentials: data.credentials,
                    rating: data.rating,
                    token: data.token,
                },
                // data: {},
            },
        },
    }
}
