export const MEMBER_ACCOUNT_FIRSTNAME_UPDATE = {
    type: "MEMBER_ACCOUNT_FIRSTNAME_UPDATE",
}
export const MEMBER_ACCOUNT_FIRSTNAME_UPDATE_FAIL = {
    type: "MEMBER_ACCOUNT_FIRSTNAME_UPDATE_FAIL",
}
export const MEMBER_ACCOUNT_FIRSTNAME_UPDATE_SUCCESS = {
    type: "MEMBER_ACCOUNT_FIRSTNAME_UPDATE_SUCCESS",
}
export const MEMBERS_ACCOUNT_MIDDLE_NAMES_UPDATE = {
    type: "MEMBERS_ACCOUNT_MIDDLE_NAMES_UPDATE",
}
export const MEMBERS_ACCOUNT_LAST_NAME_UPDATE = {
    type: "MEMBERS_ACCOUNT_LAST_NAME_UPDATE",
}
export const MEMBERS_ACCOUNT_PREFERRED_NAME_UPDATE = {
    type: "MEMBERS_ACCOUNT_PREFERRED_NAME_UPDATE",
}
export const MEMBERS_ACCOUNT_DATE_OF_BIRTH_UPDATE = {
    type: "MEMBERS_ACCOUNT_DATE_OF_BIRTH_UPDATE",
}
export const MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE = {
    type: "MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE",
}
export const MEMBERS_ACCOUNT_EMAIL_UPDATE = {
    type: "MEMBERS_ACCOUNT_EMAIL_UPDATE",
}
export const MEMBERS_ACCOUNT_MIDDLE_NAMES_UPDATE_SUCCESS = {
    type: "MEMBERS_ACCOUNT_MIDDLE_NAMES_UPDATE_SUCCESS",
}
export const MEMBERS_ACCOUNT_MIDDLE_NAMES_UPDATE_FAIL = {
    type: "MEMBERS_ACCOUNT_MIDDLE_NAMES_UPDATE_FAIL",
}
export const MEMBERS_ACCOUNT_LAST_NAME_UPDATE_SUCCESS = {
    type: "MEMBERS_ACCOUNT_LAST_NAME_UPDATE_SUCCESS",
}
export const MEMBERS_ACCOUNT_LAST_NAME_UPDATE_FAIL = {
    type: "MEMBERS_ACCOUNT_LAST_NAME_UPDATE_FAIL",
}
export const MEMBERS_ACCOUNT_PREFERRED_NAME_UPDATE_SUCCESS = {
    type: "MEMBERS_ACCOUNT_PREFERRED_NAME_UPDATE_SUCCESS",
}
export const MEMBERS_ACCOUNT_PREFERRED_NAME_UPDATE_FAIL = {
    type: "MEMBERS_ACCOUNT_PREFERRED_NAME_UPDATE_FAIL",
}
export const MEMBERS_ACCOUNT_DATE_OF_BIRTH_UPDATE_SUCCESS = {
    type: "MEMBERS_ACCOUNT_DATE_OF_BIRTH_UPDATE_SUCCESS",
}
export const MEMBERS_ACCOUNT_DATE_OF_BIRTH_UPDATE_FAIL = {
    type: "MEMBERS_ACCOUNT_DATE_OF_BIRTH_UPDATE_FAIL",
}
export const MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE_SUCCESS = {
    type: "MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE_SUCCESS",
}
export const MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE_FAIL = {
    type: "MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE_FAIL",
}
export const MEMBERS_ACCOUNT_EMAIL_UPDATE_SUCCESS = {
    type: "MEMBERS_ACCOUNT_EMAIL_UPDATE_SUCCESS",
}
export const MEMBERS_ACCOUNT_EMAIL_UPDATE_FAIL = {
    type: "MEMBERS_ACCOUNT_EMAIL_UPDATE_FAIL",
}

export const MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS = {
    type: "MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS",
}
export const MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS_SUCCESS = {
    type: "MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS_SUCCESS",
}
export const MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS_FAIL = {
    type: "MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS_FAIL",
}

export const MEMBERS_ACCOUNT_PASSWORD_UPDATE = {
    type: "MEMBERS_ACCOUNT_PASSWORD_UPDATE",
}
export const MEMBERS_ACCOUNT_PASSWORD_UPDATE_SUCCESS = {
    type: "MEMBERS_ACCOUNT_PASSWORD_UPDATE_SUCCESS",
}
export const MEMBERS_ACCOUNT_PASSWORD_UPDATE_FAIL = {
    type: "MEMBERS_ACCOUNT_PASSWORD_UPDATE_FAIL",
}
export const MEMBERS_ACCOUNT_RECOVER_OPTIONS = {
    type: "MEMBERS_ACCOUNT_RECOVER_OPTIONS",
}
export const MEMBERS_ACCOUNT_RECOVER_OPTIONS_SUCCESS = {
    type: "MEMBERS_ACCOUNT_RECOVER_OPTIONS_SUCCESS",
}
export const MEMBERS_ACCOUNT_RECOVER_OPTIONS_FAIL = {
    type: "MEMBERS_ACCOUNT_RECOVER_OPTIONS_FAIL",
}

export const MEMBERS_ACCOUNT_RECOVERY_SEND_SMS = {
    type: "MEMBERS_ACCOUNT_RECOVERY_SEND_SMS",
}
export const MEMBERS_ACCOUNT_RECOVERY_SEND_SMS_SUCCESS = {
    type: "MEMBERS_ACCOUNT_RECOVERY_SEND_SMS_SUCCESS",
}
export const MEMBERS_ACCOUNT_RECOVERY_SEND_SMS_FAIL = {
    type: "MEMBERS_ACCOUNT_RECOVERY_SEND_SMS_FAIL",
}
export const MEMBERS_ACCOUNT_RECOVERY_BY_SMS = {
    type: "MEMBERS_ACCOUNT_RECOVERY_BY_SMS",
}
export const MEMBERS_ACCOUNT_RECOVERY_BY_SMS_SUCCESS = {
    type: "MEMBERS_ACCOUNT_RECOVERY_BY_SMS_SUCCESS",
}
export const MEMBERS_ACCOUNT_RECOVERY_BY_SMS_FAIL = {
    type: "MEMBERS_ACCOUNT_RECOVERY_BY_SMS_FAIL",
}
export const MEMBERS_NAMES_GET_NAMES = {
    type: "MEMBERS_NAMES_GET_NAMES",
}
export const MEMBERS_NAMES_GET_NAMES_SUCCESS = {
    type: "MEMBERS_NAMES_GET_NAMES_SUCCESS",
}
export const MEMBERS_NAMES_GET_NAMES_FAIL = {
    type: "MEMBERS_NAMES_GET_NAMES_FAIL",
}

export const MEMBERS_ACCOUNT_PREVIOUS_NAME_DOWNLOAD_DOCUMENT = {
    type: "MEMBERS_ACCOUNT_PREVIOUS_NAME_DOWNLOAD_DOCUMENT",
}
export const MEMBERS_ACCOUNT_PREVIOUS_NAME_DOWNLOAD_DOCUMENT_SUCCESS = {
    type: "MEMBERS_ACCOUNT_PREVIOUS_NAME_DOWNLOAD_DOCUMENT_SUCCESS",
}
export const MEMBERS_ACCOUNT_PREVIOUS_NAME_DOWNLOAD_DOCUMENT_FAIL = {
    type: "MEMBERS_ACCOUNT_PREVIOUS_NAME_DOWNLOAD_DOCUMENT_FAIL",
}

export const MEMBERS_ACCOUNT_GET_NAME_TYPES = {
    type: "MEMBERS_ACCOUNT_GET_NAME_TYPES",
}
export const MEMBERS_ACCOUNT_GET_NAME_TYPES_SUCCESS = {
    type: "MEMBERS_ACCOUNT_GET_NAME_TYPES_SUCCESS",
}
export const MEMBERS_ACCOUNT_GET_NAME_TYPES_FAIL = {
    type: "MEMBERS_ACCOUNT_GET_NAME_TYPES_FAIL",
}

export const MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME = {
    type: "MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME",
}
export const MEMBERS_ACCOUNT_EDIT_PREVIOUS_NAME = {
    type: "MEMBERS_ACCOUNT_EDIT_PREVIOUS_NAME",
}
export const MEMBERS_ACCOUNT_EDIT_PREVIOUS_NAME_SUCCESS = {
    type: "MEMBERS_ACCOUNT_EDIT_PREVIOUS_NAME_SUCCESS",
}
export const MEMBERS_ACCOUNT_EDIT_PREVIOUS_NAME_FAIL = {
    type: "MEMBERS_ACCOUNT_EDIT_PREVIOUS_NAME_FAIL",
}
export const MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_SUCCESS = {
    type: "MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_SUCCESS",
}
export const MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_FAIL = {
    type: "MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_FAIL",
}

export const MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_UPLOAD_DOCUMENT = {
    type: "MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_UPLOAD_DOCUMENT",
}
export const MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_UPLOAD_DOCUMENT_SUCCESS = {
    type: "MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_UPLOAD_DOCUMENT_SUCCESS",
}
export const MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_UPLOAD_DOCUMENT_FAIL = {
    type: "MEMBERS_ACCOUNT_ADD_PREVIOUS_NAME_UPLOAD_DOCUMENT_FAIL",
}

// MEMBERS_NAME_PREVIOUS_NAME_DELETE
export const MEMBERS_ACCOUNT_DELETE_PREVIOUS_NAME = {
    type: "MEMBERS_ACCOUNT_DELETE_PREVIOUS_NAME",
}
export const MEMBERS_ACCOUNT_DELETE_PREVIOUS_NAME_SUCCESS = {
    type: "MEMBERS_ACCOUNT_DELETE_PREVIOUS_NAME_SUCCESS",
}
export const MEMBERS_ACCOUNT_DELETE_PREVIOUS_NAME_FAIL = {
    type: "MEMBERS_ACCOUNT_DELETE_PREVIOUS_NAME_FAIL",
}

export const MEMBERS_ACCOUNT_PREVIOUS_NAME_VIEW = {
    type: "MEMBERS_ACCOUNT_PREVIOUS_NAME_VIEW",
}

export const MEMBERS_ACCOUNT_PREVIOUS_NAME_VIEW_SUCCESS = {
    type: "MEMBERS_ACCOUNT_PREVIOUS_NAME_VIEW_SUCCESS",
}

export const MEMBERS_ACCOUNT_PREVIOUS_NAME_VIEW_FAIL = {
    type: "MEMBERS_ACCOUNT_PREVIOUS_NAME_VIEW_FAIL",
}

export const MEMBERS_ACCOUNT_ADD_NEW_ADDRESS = {
    type: "MEMBERS_ACCOUNT_ADD_NEW_ADDRESS",
}
export const MEMBERS_ACCOUNT_ADD_NEW_ADDRESS_SUCCESS = {
    type: "MEMBERS_ACCOUNT_ADD_NEW_ADDRESS_SUCCESS",
}
export const MEMBERS_ACCOUNT_ADD_NEW_ADDRESS_FAIL = {
    type: "MEMBERS_ACCOUNT_ADD_NEW_ADDRESS_FAIL",
}

export const MEMBERS_ACCOUNT_DELETE_ADDRESS = {
    type: "MEMBERS_ACCOUNT_DELETE_ADDRESS",
}
export const MEMBERS_ACCOUNT_DELETE_ADDRESS_SUCCESS = {
    type: "MEMBERS_ACCOUNT_DELETE_ADDRESS_SUCCESS",
}
export const MEMBERS_ACCOUNT_DELETE_ADDRESS_FAIL = {
    type: "MEMBERS_ACCOUNT_DELETE_ADDRESS_FAIL",
}

export const MEMBERS_ACCOUNT_EDIT_ADDRESS = {
    type: "MEMBERS_ACCOUNT_EDIT_ADDRESS",
}
export const MEMBERS_ACCOUNT_EDIT_ADDRESS_SUCCESS = {
    type: "MEMBERS_ACCOUNT_EDIT_ADDRESS_SUCESS",
}
export const MEMBERS_ACCOUNT_EDIT_ADDRESS_FAIL = {
    type: "MEMBERS_ACCOUNT_EDIT_ADDRESS_FAIL",
}

export const MEMBERS_ACCOUNT_VERIFY_BY_TOKEN = {
    type: "MEMBERS_ACCOUNT_VERIFY_BY_TOKEN",
}
export const MEMBERS_ACCOUNT_VERIFY_BY_TOKEN_SUCCESS = {
    type: "MEMBERS_ACCOUNT_VERIFY_BY_TOKEN_SUCCESS",
}
export const MEMBERS_ACCOUNT_VERIFY_BY_TOKEN_FAIL = {
    type: "MEMBERS_ACCOUNT_VERIFY_BY_TOKEN_FAIL",
}

export const MEMBERS_ACCOUNT_VERIFY_BY_MANUAL_CODE = {
    type: "MEMBERS_ACCOUNT_VERIFY_BY_MANUAL_CODE",
}
export const MEMBERS_ACCOUNT_VERIFY_BY_MANUAL_CODE_SUCCESS = {
    type: "MEMBERS_ACCOUNT_VERIFY_BY_MANUAL_CODE_SUCCESS",
}
export const MEMBERS_ACCOUNT_VERIFY_BY_MANUAL_CODE_FAIL = {
    type: "MEMBERS_ACCOUNT_VERIFY_BY_MANUAL_CODE_FAIL",
}
export const MEMBERS_ACCOUNT_DATE_OF_BIRTH_EDIT = {
    type: "MEMBERS_ACCOUNT_DATE_OF_BIRTH_EDIT",
}
export const MEMBERS_ACCOUNT_PREFERRED_NAME_EDIT = {
    type: "MEMBERS_ACCOUNT_PREFERRED_NAME_EDIT",
}
export const MEMBERS_ACCOUNT_LAST_NAME_EDIT = {
    type: "MEMBERS_ACCOUNT_LAST_NAME_EDIT",
}
export const MEMBERS_ACCOUNT_MIDDLE_NAMES_EDIT = {
    type: "MEMBERS_ACCOUNT_MIDDLE_NAMES_EDIT",
}
export const MEMBERS_ACCOUNT_FIRST_NAME_EDIT = {
    type: "MEMBERS_ACCOUNT_FIRST_NAME_EDIT",
}

/**
 * New Action Types 29/03/22
 */
export const MEMBERS_ACCOUNT_NATIONAL_INSURANCE_EDIT = {
    type: "MEMBERS_ACCOUNT_NATIONAL_INSURANCE_EDIT",
}
export const MEMBERS_ACCOUNT_PROFILE_ADDRESS_CONFIRM = {
    type: "MEMBERS_ACCOUNT_PROFILE_ADDRESS_CONFIRM",
}
export const MEMBERS_ACCOUNT_PROFILE_ADDRESS_CONFIRM_SUCCESS = {
    type: "MEMBERS_ACCOUNT_PROFILE_ADDRESS_CONFIRM_SUCCESS",
}
export const MEMBERS_ACCOUNT_PROFILE_ADDRESS_CONFIRM_FAIL = {
    type: "MEMBERS_ACCOUNT_PROFILE_ADDRESS_CONFIRM_FAIL",
}
export const MEMBERS_ACCOUNT_PROFILE_NAMES_CONFIRM = {
    type: "MEMBERS_ACCOUNT_PROFILE_NAMES_CONFIRM",
}
export const MEMBERS_ACCOUNT_PROFILE_NAMES_CONFIRM_SUCCESS = {
    type: "MEMBERS_ACCOUNT_PROFILE_NAMES_CONFIRM_SUCCESS",
}
export const MEMBERS_ACCOUNT_PROFILE_NAMES_CONFIRM_FAIL = {
    type: "MEMBERS_ACCOUNT_PROFILE_NAMES_CONFIRM_FAIL",
}

export const MEMBERS_ACCOUNT_NI_NUMBER_UPDATE = {
    type: "MEMBERS_ACCOUNT_NI_NUMBER_UPDATE",
}
export const MEMBERS_ACCOUNT_NI_NUMBER_UPDATE_SUCCESS = {
    type: "MEMBERS_ACCOUNT_NI_NUMBER_UPDATE_SUCCESS",
}
export const MEMBERS_ACCOUNT_NI_NUMBER_UPDATE_FAIL = {
    type: "MEMBERS_ACCOUNT_NI_NUMBER_UPDATE_FAIL",
}

export const MEMBERS_ACCOUNT_DELETE = {
    type: "MEMBERS_ACCOUNT_DELETE",
}
export const MEMBERS_ACCOUNT_DELETE_SUCCESS = {
    type: "MEMBERS_ACCOUNT_DELETE_SUCCESS",
}
export const MEMBERS_ACCOUNT_DELETE_FAIL = {
    type: "MEMBERS_ACCOUNT_DELETE_FAIL",
}
