export const REGISTRATION_INIT = {type: "REGISTRATION_INIT"}
export const REGISTRATION_INIT_SUCCESS = {type: "REGISTRATION_INIT_SUCCESS"}
export const REGISTRATION_INIT_FAIL = {type: "REGISTRATION_INIT_FAIL"}
export const REGISTRATION_ME = {
    type: "REGISTRATION_ME",
}
export const REGISTRATION_ME_SUCCESS = {
    type: "REGISTRATION_ME_SUCCESS",
}
export const REGISTRATION_ME_FAIL = {
    type: "REGISTRATION_ME_FAIL",
}
export const REGISTRATION_REFRESH = {
    type: "REGISTRATION_REFRESH",
}
export const REGISTRATION_REFRESH_SUCCESS = {
    type: "REGISTRATION_REFRESH_SUCCESS",
}
export const REGISTRATION_REFRESH_FAIL = {
    type: "REGISTRATION_REFRESH_FAIL",
}
export const REGISTRATION_COMPLETE = {type: "REGISTRATION_COMPLETE"}
export const REGISTRATION_COMPLETE_SUCCESS = {
    type: "REGISTRATION_COMPLETE_SUCCESS",
}
export const REGISTRATION_COMPLETE_FAIL = {
    type: "REGISTRATION_COMPLETE_FAIL",
}
export const REGISTRATION_VERIFY_BY_TOKEN = {
    type: "REGISTRATION_VERIFY_BY_TOKEN",
}
export const REGISTRATION_VERIFY_BY_TOKEN_SUCCESS = {
    type: "REGISTRATION_VERIFY_BY_TOKEN_SUCCESS",
}
export const REGISTRATION_VERIFY_BY_TOKEN_FAIL = {
    type: "REGISTRATION_VERIFY_BY_TOKEN_FAIL",
}
export const REGISTRATION_VERIFY_BY_CODE = {
    type: "REGISTRATION_VERIFY_BY_CODE",
}
export const REGISTRATION_VERIFY_BY_CODE_SUCCESS = {
    type: "REGISTRATION_VERIFY_BY_CODE_SUCCESS",
}
export const REGISTRATION_VERIFY_BY_CODE_FAIL = {
    type: "REGISTRATION_VERIFY_BY_CODE_FAIL",
}
export const REGISTRATION_VERIFY_RESEND = {
    type: "REGISTRATION_VERIFY_RESEND",
}
export const REGISTRATION_VERIFY_RESEND_SUCCESS = {
    type: "REGISTRATION_VERIFY_RESEND_SUCCESS",
}
export const REGISTRATION_VERIFY_RESEND_FAIL = {
    type: "REGISTRATION_VERIFY_RESEND_FAIL",
}

export const REGISTRATION_VERIFY_CAPTCHA = {
    type: "REGISTRATION_VERIFY_CAPTCHA",
}
export const REGISTRATION_VERIFY_CAPTCHA_SUCCESS = {
    type: "REGISTRATION_VERIFY_CAPTCHA_SUCCESS",
}
export const REGISTRATION_VERIFY_CAPTCHA_FAIL = {
    type: "REGISTRATION_VERIFY_CAPTCHA_FAIL",
}
export const REGISTRATION_MEMBER_VALIDATE = {
    type: "REGISTRATION_MEMBER_VALIDATE",
}
export const REGISTRATION_MEMBER_VALIDATE_SUCCESS = {
    type: "REGISTRATION_MEMBER_VALIDATE_SUCCESS",
}
export const REGISTRATION_MEMBER_VALIDATE_FAIL = {
    type: "REGISTRATION_MEMBER_VALIDATE_FAIL",
}
export const PRE_REGISTRATION = {type: "PRE_REGISTRATION"}