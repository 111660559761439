import { navigate } from "gatsby"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import startCase from "lodash/startCase"
import find from "lodash/find"
import sumBy from "lodash/sumBy"
import sum from "lodash/fp/sum"
import flow from "lodash/fp/flow"
import map from "lodash/fp/map"
import { useDispatch, useSelector } from "react-redux"
import * as base64ToBlob from "b64-to-blob"
import { saveAs } from "file-saver"
import isEqual from "lodash/isEqual"
import upperFirst from "lodash/upperFirst"

import {
    memberRefresh,
    logoutUser,
    registrationRefresh,
    setMember,
} from "~actions/index"
import {
    KYC_VELOCITY_RULES_ERROR,
    KYC_SERVICE_UNAVAILABLE_ERROR,
    KYC_INCORRECT_RESPONSES_ERROR,
    KYC_UNKNOWN_IDENTITY_ERROR,
    VELOCITY_RULES,
    SERVICE_UNAVAILABLE,
    INCORRECT_RESPONSES,
    UNKNOWN_IDENTITY,
    CONSENT_ONLINE_SERVICE_UNAVAILABLE_ERROR,
    REGISTRATION_STEP_ONE,
    REGISTRATION_STEP_TWO,
    REGISTRATION_STEP_THREE,
    TOKEN_REFRESH_WINDOW_START,
    TOKEN_REFRESH_WINDOW_END,
} from "./constants"
import { atPlural } from "./node-helpers"

/* eslint-disable no-sequences */
export const transformGenericPatchData = (type = "member", data) => {
    return {
        data: {
            type: type,
            attributes: {
                value: data,
            },
        },
    }
}
export const transformManualCode = (type = "member", data) => {
    return {
        data: {
            type: type,
            attributes: {
                username: data.username,
                manualCode: data.manualCode,
            },
        },
    }
}
export const transformResend = (type = "member", data) => {
    return {
        data: {
            type: type,
            attributes: {
                username: data,
            },
        },
    }
}
export const transformForgotPasswordReset = (type = "member", data) => {
    return {
        data: {
            type: type,
            attributes: { ...data },
        },
    }
}
export const transformCheckPasswordResetToken = (type = "member", data) => {
    return {
        data: {
            type: type,
            attributes: {
                username: data.username,
                token: data.token,
            },
        },
    }
}
export const transformTokenPostData = (type = "member", data) => {
    return {
        data: {
            type: type,
            attributes: {
                token: data,
            },
        },
    }
}
export const transformObjectData = (type = "address", data) => {
    return {
        data: {
            type: type,
            attributes: { ...data },
        },
    }
}
export const transformEditAddressData = (type = "address", data, id) => {
    return {
        data: {
            id: id,
            type: type,
            attributes: { ...data },
        },
    }
}
export const transformKycAnswerData = (type = "session", data, sessionId) => {
    return {
        data: {
            type: type,
            id: sessionId,
            attributes: {
                responses: { ...data },
            },
        },
    }
}
export const OnboardingStatuses = Object.freeze({
    VERIFICATION: "VERIFICATION",
    CONFIRMATION: "CONFIRMATION",
})
export const RecommendedActions = Object.freeze({
    PREVIOUS_ADDRESSES: "PREVIOUS_ADDRESSES",
    CONFIRMATION: "CONFIRMATION",
    SEARCH: "SEARCH",
})
export const RecommendedActionAlerts = Object.freeze({
    CONFIRMATION: "CONFIRMATION",
    PREVIOUS_NAME_UPLOAD: "PREVIOUS_NAME_UPLOAD",
    THIRD_PARTY_UPLOAD: "THIRD_PARTY_UPLOAD",
})
export const SearchStatuses = Object.freeze({
    SCHEDULED: "SCHEDULED",
    IN_PROGRESS: "IN_PROGRESS",
    PRE_VERIFIED: "PRE_VERIFIED",
    COMPLETE: "COMPLETE",
    TIMED_OUT: "TIMED_OUT",
})
export const ScheduleStatuses = Object.freeze({
    IN_PROGRESS: "IN_PROGRESS",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILED",
    ERROR: "ERROR",
    TIMED_OUT: "TIMED_OUT",
})
export const NameType = Object.freeze({
    PREVIOUS_NAME: "PREVIOUS_NAME",
    THIRD_PARTY: "THIRD_PARTY",
    THIRD_PARTY_PREVIOUS_NAME: "THIRD_PARTY_PREVIOUS_NAME",
})
export const CalloutSide = Object.freeze({
    LEFT: "LEFT",
    RIGHT: "RIGHT",
})
export const parseErrorMessageKey = (key, input) => {
    return key.indexOf(input) !== -1
}

export const debounce = (a, b, c) => {
    var d, e
    return function () {
        function h() {
            // eslint-disable-next-line no-unused-expressions
            ;(d = null), c || (e = a.apply(f, g))
        }
        var f = this,
            g = arguments
        return (
            clearTimeout(d),
            (d = setTimeout(h, b)),
            c && !d && (e = a.apply(f, g)),
            e
        )
    }
}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef()

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current()
        }
        if (delay !== null) {
            let id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [callback, delay])
}

export const changeAssetTitle = string => {
    return startCase(string)
}
export const pluckAddressMatch = array => {
    return find(array, ["type", "address"])
}
export const pluckPreviousNameMatch = array => {
    return find(array, ["type", "previousName"])
}
export const calculateSubRegisterTotal = (object, key) => {
    return sumBy(object, match => {
        return parseFloat(
            match.attributes.estimatedValue.replace(/,/g, "") ?? 0
        )
    }).toFixed(2)
}
export const calculateRegisterTotal = array => {
    return flow(
        map(o =>
            parseFloat(
                sumBy(o.matches, match =>
                    parseFloat(
                        match.attributes.estimatedValue.replace(/,/g, "") ?? 0
                    )
                )
            )
        ),
        sum
    )(array).toFixed(2)
}
export const matchesCount = (matches, redactedMatches) => {
    let total = matches.length + redactedMatches.length
    return total + (total > 1 ? " items" : " item")
}
export const sumEstimatedValueOfSlice = array => {
    return sumBy(array, match => {
        return parseFloat(match.estimatedValue.replace(/,/g, ""))
    })
}
export const sumMatchesByAssetClass = array => {
    return (
        sumBy(array, "matches.length") + sumBy(array, "redactedMatches.length")
    )
}
export const sumMatchesForAllAssets = array => {
    return (
        flow(
            map(asset => sumMatchesByAssetClass(asset)),
            sum
        )(array) ?? null
    )
}
export const insideTokenRefreshRange = tokenExpires => {
    const expiryMinutes = moment(tokenExpires).diff(moment(), "minutes")
    return (
        expiryMinutes >= TOKEN_REFRESH_WINDOW_END &&
        expiryMinutes < TOKEN_REFRESH_WINDOW_START
    )
}
export const outsideTokenRefreshRange = tokenExpires =>
    moment(tokenExpires).diff(moment(), "minutes") < TOKEN_REFRESH_WINDOW_END
export const tryAndRefresh = (getState, dispatch) => {
    const member = getState().member
    const register = getState().register
    if (
        member.tokenExpires !== undefined &&
        outsideTokenRefreshRange(member.tokenExpires)
    ) {
        dispatch(logoutUser())
        navigate("/", { replace: true })
    }
    if (register.onboarding) {
        if (
            insideTokenRefreshRange(register.tokenExpires) &&
            !register.isRefreshingToken
        ) {
            dispatch(registrationRefresh())
        }
    } else {
        if (
            insideTokenRefreshRange(member.tokenExpires) &&
            !member.isRefreshingToken
        ) {
            dispatch(memberRefresh())
                .then(result => {
                    dispatch(setMember(result.payload.data.data.attributes))
                })
                .catch(err => {})
        }
    }
}
export const apostrophize = string => {
    return [
        string.slice(0, string.length - 1),
        "'",
        string.slice(string.length - 1),
    ].join("")
}
export const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
}
export const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

export const useIsUserAuthenticated = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const { member } = useSelector(state => state)

    const dispatch = useDispatch()
    useEffect(() => {
        if (
            !member.jwtToken &&
            !member.isAuthenticated &&
            !member.tokenExpires
        ) {
            // Not even logged in
            setIsAuthenticated(false)
        }

        if (
            member.jwtToken &&
            member.isAuthenticated &&
            moment(member.tokenExpires).isAfter()
        ) {
            setIsAuthenticated(true)
        } else if (
            member.jwtToken &&
            member.isAuthenticated &&
            !moment(member.tokenExpires).isAfter()
        ) {
            setIsAuthenticated(false)
            localStorage.clear()
            dispatch(logoutUser())
        }
    }, [member])
    return isAuthenticated
}

export const followRecommendedAction = (action, member = null) => {
    switch (action) {
        case RecommendedActions.SEARCH:
            navigate("/portal/assets/assets-search", { replace: true })
            break
        case RecommendedActions.CONFIRMATION:
            navigate("/portal/assets/matches-found", {
                replace: true,
            })
            break
        case RecommendedActions.PREVIOUS_ADDRESSES:
            if (member.onboardingStatus === OnboardingStatuses.CONFIRMATION) {
                navigate("/portal/previous-addresses", {
                    replace: true,
                })
            } else {
                navigate("/portal/confirm-addresses", {
                    replace: true,
                })
            }
            break
        default:
            navigate("/portal/dashboard", { replace: true })
            break
    }
}

export const removeTrailingSlash = string => string.replace(/\/$/, "")

export const contentTypes = {
    png: "image/png",
    jpeg: "image/jpeg",
    jpg: "image/jpg",
    pdf: "application/pdf",
    gif: "image/gif ",
    tiff: "image/tiff",
}
export const base64Prepends = {
    png: `data:${contentTypes.png};base64`,
    jpeg: `data:${contentTypes.jpeg};base64`,
    jpg: `data:${contentTypes.jpg};base64`,
    pdf: `data:${contentTypes.pdf};base64`,
    gif: `data:${contentTypes.gif};base64`,
    tiff: `data:${contentTypes.tiff};base64`,
}
export const defaultDownloadName = "download"

export function getExtFromBase64(base64) {
    let ext
    if (typeof base64 === "string") {
        ext = Object.keys(base64Prepends).find(
            key => base64.indexOf(base64Prepends[key]) === 0
        )
    }

    // if extension was found, return it, otherwise throw.
    if (ext) {
        return ext
    } else {
        throw new Error(
            `props.base64 on <Base64Downloader/> has invalid or undefined extension. expected ${Object.keys(
                contentTypes
            ).join(", ")}`
        )
    }
}

/**
 * Triggers a browser file download from a base64 string
 *
 * @param {string} base64 - base64 image string including prepend. e.g. data:image/png;base64,iVBORw0KGgo...
 * @param {string} name - name of the file which will be downloaded
 */
export function triggerBase64Download(base64, name = defaultDownloadName) {
    const ext = getExtFromBase64(base64)

    // if the getExtFromBase64 method doesn't throw, we have a valid extension!
    const prepend = base64Prepends[ext]
    const contentType = contentTypes[ext]
    const cleanedBase64 = base64.replace(`${prepend},`, "")

    // generate a blob, then a file and then save the file.
    const blob = base64ToBlob(cleanedBase64, contentType)
    const file = new File([blob], `${name}.${ext}`, { type: prepend })
    saveAs(file)
}

export const getKycServiceErrorMessage = detail => {
    switch (detail) {
        case VELOCITY_RULES:
            return KYC_VELOCITY_RULES_ERROR
        case SERVICE_UNAVAILABLE:
            return KYC_SERVICE_UNAVAILABLE_ERROR
        case INCORRECT_RESPONSES:
            return KYC_INCORRECT_RESPONSES_ERROR
        case UNKNOWN_IDENTITY:
            return KYC_UNKNOWN_IDENTITY_ERROR
        default:
            break
    }
}
export const getConsentOnlineErrorMessage = detail => {
    switch (detail) {
        case SERVICE_UNAVAILABLE:
            return CONSENT_ONLINE_SERVICE_UNAVAILABLE_ERROR
        default:
            break
    }
}

export const mapNamesToRows = names => {
    return names.map(name => {
        return {
            id: name.id,
            ...name.attributes,
        }
    })
}

export const displayName = (firstName, middleNames, lastName) => {
    let displayName = ""
    if (firstName) {
        displayName = displayName.concat(" ", firstName)
    }
    if (middleNames) {
        displayName = displayName.concat(" ", middleNames)
    }
    if (lastName) {
        displayName = displayName.concat(" ", lastName)
    }
    return displayName.trim()
}

export const mapDisplayName = names => {
    return names.map(name => {
        return {
            id: name.id,
            compact: displayName(
                name.attributes.firstName,
                name.attributes.middleNames,
                name.attributes.lastName
            ),
        }
    })
}

export const compareFormMemberToStateMember = (formUser, stateUser) => {
    return isEqual(formUser, stateUser)
}

export const calculateFormStep = error => {
    switch (error.field) {
        case "email":
            return REGISTRATION_STEP_ONE
        case "password":
            return REGISTRATION_STEP_ONE
        case "confirmPassword":
            return REGISTRATION_STEP_ONE
        case "firstName":
            return REGISTRATION_STEP_TWO
        case "middleNames":
            return REGISTRATION_STEP_TWO
        case "lastName":
            return REGISTRATION_STEP_TWO
        case "preferredName":
            return REGISTRATION_STEP_TWO
        case "dateOfBirth":
            return REGISTRATION_STEP_TWO
        case "phoneNumber":
            return REGISTRATION_STEP_THREE
        case "contactAddress":
            return REGISTRATION_STEP_THREE
        default:
    }
}

export const handleRegistrationCompleteValidationCheck = errors => {
    if (errors.length) {
        return Math.min(...errors.map(error => calculateFormStep(error)))
    } else {
        return false
    }
}

export const checkForRedactedMatches = (results, owned = false) => {
    if (results.length > 0) {
        return results.filter(
            res =>
                res.type === "match" &&
                !res.relationships?.hasOwnProperty("thirdParty") &&
                (res.attributes.redacted === "true" ||
                    res.attributes.redacted === true)
        )
    }
    return []
}

export const filterPreviousNameByStatus = (
    res,
    status,
    includeArray,
    excludeArray
) =>
    res.type === "previousName" &&
    includeArray.includes(res.id) &&
    res.attributes.status === status

export const filterThirdPartyNameByStatus = (res, status, idArray) =>
    (res.type === "thirdParty" || res.type === "previousName") &&
    idArray.includes(res.id) &&
    res.attributes.status === status

export const getUniquePreviousNameIds = redactedMatches => {
    return [
        ...new Set(
            redactedMatches.map(
                match => match?.relationships?.previousName?.data?.id
            )
        ),
    ]
}

export const getUniqueThirdPartyPreviousNameIds = names => {
    return [...new Set(names.map(name => name.id))]
}

export const filterIncludedByStatus = (
    results,
    status,
    personalNameIds,
    thirdParty = false
) => {
    return results.filter(res =>
        !thirdParty
            ? filterPreviousNameByStatus(res, status, personalNameIds)
            : filterThirdPartyNameByStatus(res, status, personalNameIds)
    )
}

export const parseBoolFromApi = (key, value, deep = false) => {
    if (deep) {
        if (value.length) {
            return value[0].hasOwnProperty(key)
                ? JSON.parse(value[0][key]) === true
                : false
        }
    } else {
        return value.hasOwnProperty(key)
            ? JSON.parse(value[key]) === true
            : false
    }
}

export const mapPreviousNamesToState = result => {
    return result.payload.data.data.map(name => {
        return {
            ...name,
            type: find(result.payload.data.included, {
                id: name.id,
            }).type,
            attributes: {
                ...name.attributes,
                current: name.attributes.hasOwnProperty("current")
                    ? JSON.parse(name.attributes.current)
                    : false,
            },
            ...(find(result.payload.data.included, {
                id: name.id,
            }) && {
                included: find(result.payload.data.included, {
                    id: name.id,
                }),
            }),
        }
    })
}

export const formatPublishedDate = date => {
    if (date) {
        return upperFirst(moment(date).fromNow()) + ":"
    }
}

export const mapThirdPartyNamesToRows = (
    previousName,
    disableDateOfBirth = false
) => {
    if (previousName.type === "thirdParty") {
        return {
            ...previousName,
            id: previousName?.id,
            firstName:
                previousName?.attributes?.firstName !== ""
                    ? previousName?.attributes?.firstName
                    : "",
            middleNames:
                previousName?.attributes?.middleNames !== ""
                    ? previousName?.attributes?.middleNames
                    : "",
            lastName:
                previousName?.attributes?.lastName !== ""
                    ? previousName?.attributes?.lastName
                    : "",
            ...(!disableDateOfBirth
                ? {
                      dateOfBirth:
                          previousName?.attributes?.dateOfBirth !== ""
                              ? moment(
                                    previousName?.attributes?.dateOfBirth
                                ).format("DD/MM/YYYY")
                              : "",
                  }
                : {}),
            status: previousName?.attributes?.status
                ? previousName?.attributes?.status
                : previousName.included?.attributes.status
                ? previousName.included?.attributes.status
                : "",
        }
    } else {
        return {
            ...previousName,
            id: previousName?.id,
            firstName:
                previousName?.attributes?.firstName !== ""
                    ? previousName?.attributes?.firstName
                    : "",
            middleNames:
                previousName?.attributes?.middleNames !== ""
                    ? previousName?.attributes?.middleNames
                    : "",
            lastName:
                previousName?.attributes?.lastName !== ""
                    ? previousName?.attributes?.lastName
                    : "",
            ...(!disableDateOfBirth
                ? {
                      dateOfBirth:
                          previousName?.attributes?.dateOfBirth !== ""
                              ? moment(
                                    previousName?.attributes?.dateOfBirth
                                ).format("DD/MM/YYYY")
                              : "",
                  }
                : {}),
            status:
                previousName.included?.attributes?.status !== ""
                    ? previousName.included?.attributes.status
                    : "",
        }
    }
}

export const getThirdPartyMatchCount = (id, stats) => {
    return stats.find(stat => stat.id === id)?.count || 0
}
export const getPrimaryAccountMatchSum = stats => {
    return stats.reduce((n, { count }) => n + count, 0) || 0
}

export const getFirstThirdPartyWithNoAddresses = thirdPartyAccounts => {
    return thirdPartyAccounts.find(thirdParty => thirdParty.addressCount === 0)
}

const getPrettyDocumentType = (name, documentTypes) => {
    if (name !== null && documentTypes.length > 0) {
        return (
            documentTypes.filter(
                documentType =>
                    documentType.id === name?.attributes?.documentType
            )[0].attributes.label ?? ""
        )
    } else {
        return ""
    }
}

export const parsePreviousName = (previousName, documentTypes) => {
    return {
        type: "previousName",
        firstName: previousName?.attributes.firstName,
        middleNames:
            previousName?.attributes?.middleNames !== ""
                ? previousName?.attributes?.middleNames
                : "",
        lastName: previousName?.attributes?.lastName,
        effectiveDate: moment(previousName?.attributes?.effectiveDate).format(
            "DD/MM/YYYY"
        ),
        documentTypeNiceName: getPrettyDocumentType(
            previousName,
            documentTypes
        ),
    }
}

export const parseThirdParty = (thirdParty, documentTypes) => {
    return {
        type: "thirdParty",
        firstName: thirdParty?.attributes.firstName,
        middleNames:
            thirdParty?.attributes?.middleNames !== ""
                ? thirdParty?.attributes?.middleNames
                : "",
        lastName: thirdParty?.attributes?.lastName,
        dateOfBirth: moment(thirdParty?.attributes?.dateOfBirth).format(
            "DD/MM/YYYY"
        ),
        documentTypeNiceName: getPrettyDocumentType(thirdParty, documentTypes),
    }
}
export const formatAsCurrency = value => {
    const formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
    return formatter.format(value)
}
export const goRegistration = () => {
    navigate("/portal/registration", {
        state: {
            action: "LOGOUT_PURGE",
        },
    })
}

export const ContactMethods = Object.freeze({
    EMAIL: "EMAIL",
    PDF: "PDF",
    CLIENT: "CLIENT",
    NONE: "NONE",
})

export const assetClassToPrettyClass = assetClass => {
    return startCase(atPlural[assetClass])
}
