export * from "./errorTypes"
export * from "./historicAddressTypes"
export * from "./memberTypes"
export * from "./registrationTypes"
export * from "./notificationTypes"
export const LOGOUT_REQUEST = { type: "LOGOUT_REQUEST" }
export const LOGOUT_SUCCESS = { type: "LOGOUT_SUCCESS" }
export const LOGOUT_FAIL = { type: "LOGOUT_FAIL" }

export const LOGIN = { type: "LOGIN" }
export const LOGIN_REFRESH = { type: "LOGIN_REFRESH" }
export const LOGIN_REFRESH_SUCCESS = { type: "LOGIN_REFRESH_SUCCESS" }
export const KYC_INIT = { type: "KYC_INIT" }
export const KYC_INIT_SUCCESS = { type: "KYC_INIT_SUCCESS" }
export const KYC_INIT_FAIL = { type: "KYC_INIT_FAIL" }
export const KYC_COMPLETE = { type: "KYC_COMPLETE" }
export const KYC_COMPLETE_SUCCESS = { type: "KYC_COMPLETE_SUCCESS" }
export const KYC_COMPLETE_FAIL = { type: "KYC_COMPLETE_FAIL" }
export const LOGIN_SUCCESS = { type: "LOGIN_SUCCESS" }
export const LOGIN_FAIL = { type: "LOGIN_FAIL" }
export const GET_AVATARS = { type: "GET_AVATARS" }
export const GET_COUNTRIES = { type: "GET_COUNTRIES" }
export const GET_COUNTRIES_SUCCESS = { type: "GET_COUNTRIES_SUCCESS" }
export const GET_COUNTRIES_FAIL = { type: "GET_COUNTRIES_FAIL" }
export const SCHEDULE_A_SEARCH = { type: "SCHEDULE_A_SEARCH" }
export const MEMBER_CHECK_PASSWORD_RESET_TOKEN = {
    type: "MEMBER_CHECK_PASSWORD_RESET_TOKEN",
}
export const MEMBER_CHECK_PASSWORD_RESET_TOKEN_SUCCESS = {
    type: "MEMBER_CHECK_PASSWORD_RESET_TOKEN_SUCCESS",
}
export const MEMBER_CHECK_PASSWORD_RESET_TOKEN_FAIL = {
    type: "MEMBER_CHECK_PASSWORD_RESET_TOKEN_FAIL",
}
export const MEMBER_PASSWORD_RESET_BY_MANUAL_CODE = {
    type: "MEMBER_PASSWORD_RESET_BY_MANUAL_CODE",
}
export const MEMBER_PASSWORD_RESET_BY_MANUAL_CODE_SUCCESS = {
    type: "MEMBER_PASSWORD_RESET_BY_MANUAL_CODE_SUCCESS",
}
export const MEMBER_PASSWORD_RESET_BY_MANUAL_CODE_FAIL = {
    type: "MEMBER_PASSWORD_RESET_BY_MANUAL_CODE_FAIL",
}
export const MEMBER_PASSWORD_RESET_BY_TOKEN = {
    type: "MEMBER_PASSWORD_RESET_BY_TOKEN",
}
export const MEMBER_PASSWORD_RESET_BY_TOKEN_SUCCESS = {
    type: "MEMBER_PASSWORD_RESET_BY_TOKEN_SUCCESS",
}
export const MEMBER_PASSWORD_RESET_BY_TOKEN_FAIL = {
    type: "MEMBER_PASSWORD_RESET_BY_TOKEN_FAIL",
}
export const MEMBER_LOST_PASSWORD = { type: "MEMBER_LOST_PASSWORD" }
export const MEMBER_LOST_PASSWORD_SUCCESS = {
    type: "MEMBER_LOST_PASSWORD_SUCCESS",
}
export const MEMBER_LOST_PASSWORD_FAIL = { type: "MEMBER_LOST_PASSWORD_FAIL" }
export const SCHEDULE_A_SEARCH_SUCCESS = { type: "SCHEDULE_A_SEARCH_SUCCESS" }
export const SCHEDULE_A_SEARCH_FAIL = { type: "SCHEDULE_A_SEARCH_FAIL" }
export const SCHEDULE_A_SEARCH_STATUS = { type: "SCHEDULE_A_SEARCH_STATUS" }
export const SCHEDULE_A_SEARCH_STATUS_SUCCESS = {
    type: "SCHEDULE_A_SEARCH_STATUS_SUCCESS",
}
export const SCHEDULE_A_SEARCH_STATUS_FAIL = {
    type: "SCHEDULE_A_SEARCH_STATUS_FAIL",
}
export const GET_SEARCH_DETAILS = { type: "GET_SEARCH_DETAILS" }
export const GET_SEARCH_DETAILS_SUCCESS = {
    type: "GET_SEARCH_DETAILS_SUCCESS",
}
export const RESET_SEARCH_DETAILS = {
    type: "RESET_SEARCH_DETAILS",
}
export const GET_SEARCH_DETAILS_FAIL = { type: "GET_SEARCH_DETAILS_FAIL" }
export const SCHEDULE_A_SEARCH_RESULTS = { type: "SCHEDULE_A_SEARCH_RESULTS" }
export const SCHEDULE_A_SEARCH_RESULTS_SUCCESS = {
    type: "SCHEDULE_A_SEARCH_RESULTS_SUCCESS",
}
export const SCHEDULE_A_SEARCH_RESULTS_FAIL = {
    type: "SCHEDULE_A_SEARCH_RESULTS_FAIL",
}
export const MEMBER_VERIFY_CHANGE_EMAIL = {
    type: "MEMBER_VERIFY_CHANGE_EMAIL",
}
export const MEMBER_VERIFY_CHANGE_EMAIL_SUCCESS = {
    type: "MEMBER_VERIFY_CHANGE_EMAIL_SUCCESS",
}
export const MEMBER_VERIFY_CHANGE_EMAIL_FAIL = {
    type: "MEMBER_VERIFY_CHANGE_EMAIL_FAIL",
}

export const MEMBER_ASSETS_EMAIL_CLAIM_RESULT = {
    type: "MEMBER_ASSETS_EMAIL_CLAIM_RESULT",
}
export const MEMBER_ASSETS_EMAIL_CLAIM_RESULT_SUCCESS = {
    type: "MEMBER_ASSETS_EMAIL_CLAIM_RESULT_SUCCESS",
}
export const MEMBER_ASSETS_EMAIL_CLAIM_RESULT_FAIL = {
    type: "MEMBER_ASSETS_EMAIL_CLAIM_RESULT_FAIL",
}
export const MEMBER_ASSETS_CLAIM_RESULT = {
    type: "MEMBER_ASSETS_CLAIM_RESULT",
}
export const MEMBER_ASSETS_CLAIM_RESULT_SUCCESS = {
    type: "MEMBER_ASSETS_CLAIM_RESULT_SUCCESS",
}
export const MEMBER_ASSETS_CLAIM_RESULT_FAIL = {
    type: "MEMBER_ASSETS_CLAIM_RESULT_FAIL",
}
export const MEMBER_ASSETS_UNCLAIM_RESULT = {
    type: "MEMBER_ASSETS_UNCLAIM_RESULT",
}
export const MEMBER_ASSETS_UNCLAIM_RESULT_SUCCESS = {
    type: "MEMBER_ASSETS_UNCLAIM_RESULT_SUCCESS",
}
export const MEMBER_ASSETS_UNCLAIM_RESULT_FAIL = {
    type: "MEMBER_ASSETS_UNCLAIM_RESULT_FAIL",
}
export const MEMBER_ASSETS_DONATE_RESULT = {
    type: "MEMBER_ASSETS_DONATE_RESULT",
}
export const MEMBER_ASSETS_DONATE_RESULT_SUCCESS = {
    type: "MEMBER_ASSETS_DONATE_RESULT_SUCCESS",
}
export const MEMBER_ASSETS_DONATE_RESULT_FAIL = {
    type: "MEMBER_ASSETS_DONATE_RESULT_FAIL",
}
export const MEMBER_ASSETS_UNDONATE_RESULT = {
    type: "MEMBER_ASSETS_UNDONATE_RESULT",
}
export const MEMBER_ASSETS_UNDONATE_RESULT_SUCCESS = {
    type: "MEMBER_ASSETS_UNDONATE_RESULT_SUCCESS",
}
export const MEMBER_ASSETS_UNDONATE_RESULT_FAIL = {
    type: "MEMBER_ASSETS_UNDONATE_RESULT_FAIL",
}

export const ASSETS_SEARCH_RESULTS_SUMMARY = {
    type: "ASSETS_SEARCH_RESULTS_SUMMARY",
}
export const ASSETS_SEARCH_RESULTS_SUMMARY_SUCCESS = {
    type: "ASSETS_SEARCH_RESULTS_SUMMARY_SUCCESS",
}
export const ASSETS_SEARCH_RESULTS_SUMMARY_FAIL = {
    type: "ASSETS_SEARCH_RESULTS_SUMMARY_FAIL",
}

export const ASSETS_SEARCH_RESULTS_CLAIMED = {
    type: "ASSETS_SEARCH_RESULTS_CLAIMED",
}
export const ASSETS_SEARCH_RESULTS_CLAIMED_SUCCESS = {
    type: "ASSETS_SEARCH_RESULTS_CLAIMED_SUCCESS",
}
export const ASSETS_SEARCH_RESULTS_CLAIMED_FAIL = {
    type: "ASSETS_SEARCH_RESULTS_CLAIMED_FAIL",
}

export const GET_SEARCH_DETAILS_MESSAGING = {
    type: "GET_SEARCH_DETAILS_MESSAGING",
}
export const GET_SEARCH_DETAILS_MESSAGING_SUCCESS = {
    type: "GET_SEARCH_DETAILS_MESSAGING_SUCCESS",
}
export const GET_SEARCH_DETAILS_MESSAGING_FAIL = {
    type: "GET_SEARCH_DETAILS_MESSAGING_FAIL",
}

export const MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS = {
    type: "MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS",
}
export const MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_SUCCESS = {
    type: "MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_SUCCESS",
}
export const MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_FAIL = {
    type: "MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_FAIL",
}
export const MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_PERSON = {
    type: "MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_PERSON",
}
export const MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON = {
    type: "MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON",
}
export const MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON_SUCCESS = {
    type: "MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON_SUCCESS",
}
export const MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON_FAIL = {
    type: "MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON_FAIL",
}
export const MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_PERSON_SUCCESS = {
    type: "MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_PERSON_SUCCESS",
}
export const MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_PERSON_FAIL = {
    type: "MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_PERSON_FAIL",
}
export const GET_PARTNERS = {
    type: "GET_PARTNERS",
}
export const GET_PARTNERS_SUCCESS = {
    type: "GET_PARTNERS_SUCCESS",
}
export const GET_PARTNERS_FAIL = {
    type: "GET_PARTNERS_FAIL",
}
export const REFER_PARTNER = {
    type: "REFER_PARTNER",
}
export const REFER_PARTNER_SUCCESS = {
    type: "REFER_PARTNER_SUCCESS",
}
export const REFER_PARTNER_FAIL = {
    type: "REFER_PARTNER_FAIL",
}
export const SUBMIT_FEEDBACK = {
    type: "SUBMIT_FEEDBACK",
}
export const SUBMIT_FEEDBACK_SUCCESS = {
    type: "SUBMIT_FEEDBACK_SUCCESS",
}
export const SUBMIT_FEEDBACK_FAIL = {
    type: "SUBMIT_FEEDBACK_FAIL",
}
export const GET_FEEDBACK = {
    type: "GET_FEEDBACK",
}
export const GET_FEEDBACK_SUCCESS = {
    type: "GET_FEEDBACK_SUCCESS",
}
export const GET_FEEDBACK_FAIL = {
    type: "GET_FEEDBACK_FAIL",
}
