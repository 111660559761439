export const SEARCH_DSAR_CONTACTS = { type: "SEARCH_DSAR_CONTACTS" }
export const SEARCH_DSAR_CONTACTS_SUCCESS = {
    type: "SEARCH_DSAR_CONTACTS_SUCCESS",
}
export const SEARCH_DSAR_CONTACTS_FAIL = {
    type: "SEARCH_DSAR_CONTACTS_FAIL",
}

export const GET_DSAR_CONTACT = { type: "GET_DSAR_CONTACT" }
export const GET_DSAR_CONTACT_SUCCESS = { type: "GET_DSAR_CONTACT_SUCCESS" }
export const GET_DSAR_CONTACT_FAIL = { type: "GET_DSAR_CONTACT_FAIL" }

export const DOWNLOAD_SUBJECT_ACCESS_REQUEST = {
    type: "DOWNLOAD_SUBJECT_ACCESS_REQUEST",
}
export const DOWNLOAD_SUBJECT_ACCESS_REQUEST_SUCCESS = {
    type: "DOWNLOAD_SUBJECT_ACCESS_REQUEST_SUCCESS",
}
export const DOWNLOAD_SUBJECT_ACCESS_REQUEST_FAIL = {
    type: "DOWNLOAD_SUBJECT_ACCESS_REQUEST_FAIL",
}

export const SEND_DSAR_EMAIL = { type: "SEND_DSAR_EMAIL" }
export const SEND_DSAR_EMAIL_SUCCESS = { type: "SEND_DSAR_EMAIL_SUCCESS" }
export const SEND_DSAR_EMAIL_FAIL = { type: "SEND_DSAR_EMAIL_FAIL" }
