export const baseUrl = process.env.GATSBY_API_URL
export const dashboardNoMatchesAlert = `We haven't found any matches for you yet, but that
    doesn't mean we won't. We are regularly checking and as more and more companies work with
    us, the chances of finding a match will increase. As soon as we find something we will
    notify you by email.`
export const KYC_VELOCITY_RULES_ERROR = {
    title: "IDENTITY CHECK FAILED",
    description:
        "Sorry, but it has not been possible to check your identity. To help prevent fraud " +
        "you are only permitted to attempt verification twice in 24 hours and three times in " +
        "7 days. Don't worry though, as you can try again once these limits have expired.",
    primaryCta: {
        label: "Continue",
        action: "/portal/dashboard",
    },
}
export const KYC_SERVICE_UNAVAILABLE_ERROR = {
    title: "IDENTITY CHECK ERROR",
    description:
        "Sorry, but it has not been possible to check your identity due to a temporary " +
        "problem accessing your credit file data. This is nothing to worry about and " +
        "should be resolved shortly. Please try again later.",
    primaryCta: {
        label: "Continue",
        action: "/portal/dashboard",
    },
}
export const CONSENT_ONLINE_SERVICE_UNAVAILABLE_ERROR = {
    title: "We've hit a problem",
    description:
        "Sorry, but it has not been possible to check your identity due to a temporary " +
        "problem accessing online banking. This is nothing to worry about and " +
        "should be resolved shortly. Please try again later.",
    primaryCta: {
        label: "Continue",
        action: "/portal/dashboard",
    },
}
export const KYC_INCORRECT_RESPONSES_ERROR = {
    title: "IDENTITY CHECK FAILED",
    description:
        "We were not able to verify your identity based on the answers provided. " +
        "You can try again, but only once more in the current 24 hour period. If " +
        "you can't answer the questions correctly after that, you may have to wait " +
        "several days before you can make another attempt.",
    primaryCta: {
        label: "Try again",
        action: "/portal/assets/matches-found",
    },
    secondaryCta: {
        label: "Skip for now",
        action: "/portal/dashboard",
    },
}
export const KYC_UNKNOWN_IDENTITY_ERROR = {
    title: "IDENTITY CHECK FAILED",
    description:
        "We were not able to verify your name, address and date of birth. " +
        "If you have moved home or changed your name very recently, your credit " +
        "file may not have been updated yet, but we may be able to verify you at " +
        "your previous address or using your previous name.",
    primaryCta: {
        label: "Update my details",
        action: "/portal/my-account/personal-details",
    },
    secondaryCta: {
        label: "Skip for now",
        action: "/portal/dashboard",
    },
}
export const VELOCITY_RULES = "idverification.error.VELOCITY_RULES"
export const SERVICE_UNAVAILABLE = "idverification.error.SERVICE_UNAVAILABLE"
export const INCORRECT_RESPONSES = "idverification.error.INCORRECT_RESPONSES"
export const UNKNOWN_IDENTITY = "idverification.error.UNKNOWN_IDENTITY"

export const REJECTION_REASON_ILLEGIBLE = "ILLEGIBLE"
export const REJECTION_REASON_INVALID_PROOF = "INVALID_PROOF"
export const REJECTION_REASON_INCORRECT_PREVIOUS_NAME =
    "INCORRECT_PREVIOUS_NAME"
export const REJECTION_REASON_INCORRECT_CURRENT_NAME = "INCORRECT_CURRENT_NAME"
export const REJECTION_REASON_INCORRECT_NEW_NAME = "INCORRECT_NEW_NAME"
export const REJECTION_REASON_INCORRECT_FIRSTPARTY_NAME =
    "INCORRECT_FIRSTPARTY_NAME"
export const REJECTION_REASON_UNDOCUMENTED_FIRSTPARTY_NAME =
    "UNDOCUMENTED_FIRSTPARTY_NAME"
export const REJECTION_REASON_INCORRECT_FIRSTPARTY_ADDRESS =
    "INCORRECT_FIRSTPARTY_ADDRESS"
export const REJECTION_REASON_INCORRECT_THIRDPARTY_NAME =
    "INCORRECT_THIRDPARTY_NAME"
export const REJECTION_REASON_INCORRECT_THIRDPARTY_ADDRESS =
    "INCORRECT_THIRDPARTY_ADDRESS"

export const DOCUMENT_UPLOAD_ENTITY_TYPE_PREVIOUSNAME = "PreviousName"
export const DOCUMENT_UPLOAD_ENTITY_TYPE_THIRDPARTY = "ThirdParty"
export const DOCUMENT_UPLOAD_EMPTY_ERROR = "Please select a document to upload"
export const DOCUMENT_UPLOAD_GENERIC_ERROR =
    "Unable to upload document - check file type and size (max 4Mb)"
/**
 * Personal Details Messaging
 */
export const FIRST_NAME_REQUIRED_MESSAGE = "First Name is required"
export const LAST_NAME_REQUIRED_MESSAGE = "Last Name is required"
export const PREFERRED_NAME_REQUIRED_MESSAGE = "Preferred Name is required"
export const DATE_OF_BIRTH_REQUIRED_MESSAGE = "Date of Birth is required"
/**
 * Account Messaging
 */
export const EMAIL_ADDRESS_REQUIRED_MESSAGE = "Email address is required"
export const PASSWORD_REQUIRED_MESSAGE = "Password is required"
export const NEW_PASSWORD_REQUIRED_MESSAGE = "New Password is required"
/**
 * Address Detail Messaging
 */
export const COUNTRY_REQUIRED_MESSAGE = "Country is required"
export const POSTCODE_REQUIRED_MESSAGE = "Postcode is required"
export const PHONE_NUMBER_REQUIRED_MESSAGE = "Phone Number is required"
export const NI_NUMBER_REQUIRED = "National Insurance Number is required"
export const MANUAL_CODE_REQUIRED_MESSAGE = "Manual Code is required"
export const ADDRESS_ONE_REQUIRED_MESSAGE = "Address 1 is required"
export const DOCUMENT_TYPE_REQUIRED_MESSAGE = "Document Type is required"
export const EFFECTIVE_DATE_REQUIRED_MESSAGE = "Date of Name Change is required"
export const DOCUMENT_UPLOADED_FLASH_MESSAGE =
    "Your document has been successfully uploaded."
export const ADD_NAME_FLASH_MESSAGE =
    "Your additional name has been successfully registered."
export const EDIT_NAME_FLASH_MESSAGE =
    "Your additional name has been successfully updated."
export const ADD_THIRD_PARTY_NAME_FLASH_MESSAGE =
    "The additional name for the Third Party has been successfully registered."
export const EDIT_THIRD_PARTY_FLASH_MESSAGE =
    "The Third party has been successfully updated."
export const EDIT_THIRD_PARTY_NAME_FLASH_MESSAGE =
    "The additional name for the Third party has been successfully updated."
/*
 * Dashboard notification alert messages & actions
 */
export const ALERT_CONFIRMATION_MESSAGE = `Great news! We have a potential match. Before we can show you
                    the details, we need you to confirm your identity. You can do this by either answering
                    some questions relating to your financial history or using your online banking
                    credentials. To start the process, click the button below.`
export const ALERT_CONFIRMATION_ACTION = "Confirm my identity"
export const ALERT_THIRD_PARTY_UPLOAD_MESSAGE = `At least one of your matches relates to a Third Party
                    you have registered. Before you can view the details, you will need to upload a photo
                    or scan of the document which evidences your legal capacity by clicking the button below.`
export const ALERT_THIRD_PARTY_UPLOAD_ACTION = "Upload Document"
export const ALERT_PREVIOUS_NAME_UPLOAD_MESSAGE = `At least one of your matches is associated with an additional
                    name that has not yet been approved. Before you can view the details, you will need to
                    upload a photo or scan of the supporting legal document by clicking the button below.`
export const ALERT_PREVIOUS_NAME_UPLOAD_ACTION = "Upload my document"
export const ALERT_THIRD_PARTY_ADDRESS_MESSAGE = `You have registered a Third Party but have not yet provided
                    any of their addresses. In order for us to start searching for lost assets in the name
                    of this Third Party, please add at least one address by clicking the button below.`
export const ALERT_THIRD_PARTY_ADDRESS_ACTION = "Add address"
export const ALERT_THIRD_PARTY_PREVIOUS_NAME_UPLOAD_MESSAGE = `At least one of your Third Party matches is
                    associated with an additional name that has not yet been approved. Before you can view
                    the details, you will need to upload a photo or scan of the supporting legal document by
                    clicking the button below.`
export const ALERT_THIRD_PARTY_PREVIOUS_NAME_UPLOAD_ACTION = "Upload Document"

export const MATCH_PROMPT_CONFIRM_IDENTITY_MESSAGE = `Before we can show you the details of
                    your matches, we need you to confirm your identity. You can do this by
                    either answering some questions relating to your financial history or
                    using your online banking credentials. To start the process, click the
                    button below.`
export const MATCH_PROMPT_CONFIRM_IDENTITY_ACTION = `Confirm my identity`
export const MATCH_PROMPT_PENDING_PREVIOUS_NAME_MESSAGE = `At least one of your matches is
                    associated with an additional name that has not yet been approved.
                    Before you can view the details, you will need to upload a photo or
                    scan of the supporting legal document by clicking the button below.`
export const MATCH_PROMPT_PENDING_PREVIOUS_NAME_ACTION = `Upload my document`
export const MATCH_PROMPT_SUBMITTED_PREVIOUS_NAME_MESSAGE = `At least one of your matches
                    is associated with an additional name that has not yet been approved.
                    To check the status of additional names you have registered, please `
export const MATCH_PROMPT_SUBMITTED_PREVIOUS_NAME_ACTION = `click here`
export const MATCH_PROMPT_PENDING_THIRD_PARTY_MESSAGE = `Before we can show you the details
                    of any matches for this Third Party, you will need to upload a photo
                    or scan of the document which evidences your legal capacity by clicking
                    the button below.`
export const MATCH_PROMPT_PENDING_THIRD_PARTY_ACTION = `Upload document`
export const MATCH_PROMPT_PENDING_THIRD_PARTY_PREVIOUS_NAME_MESSAGE = `At least one of these
                    matches is associated with an additional name for the Third Party that has
                    not yet been approved. Before you can view the details, you will need to
                    upload a photo or scan of the supporting legal document by clicking the
                    button below.`
export const MATCH_PROMPT_PENDING_THIRD_PARTY_PREVIOUS_NAME_ACTION = `Upload document`
export const MATCH_PROMPT_SUBMITTED_THIRD_PARTY_MESSAGE = `The document you have uploaded to
                    evidence your legal capacity for this Third Party has not yet been
                    approved. To check the status of Third Parties you have registered, please `
export const MATCH_PROMPT_SUBMITTED_THIRD_PARTY_ACTION = `click here`
export const MATCH_PROMPT_SUBMITTED_THIRD_PARTY_PREVIOUS_NAME_MESSAGE = `At least one of these
                    matches is associated with an additional name for the Third Party that has
                    not yet been approved. To check the status of additional names you have
                    registered for the Third Party, please `
export const MATCH_PROMPT_SUBMITTED_THIRD_PARTY_PREVIOUS_NAME_ACTION = `click here`

export const NATIONAL_INSURANCE_FORM_SUCCESS_TEXT =
    "The NI Number has been updated successfully."
export const ADD_NEW_NAME_OR_PREVIOUS = "Add previous or new name"
export const CONFIRM_PREVIOUS_NAMES = "CONFIRM_PREVIOUS_NAMES"
export const ADD_PREVIOUS_ADDRESSES = "Add previous addresses"
export const CONFIRM_ADDRESS_INTRO_TEXT = `Please check the list of addresses below and if any are missing,
                    you can add them by clicking the **Add Previous
                    Address** button. Once all your addresses are listed,
                    click the **Confirm Address History** button.`
export const CONFIRM_NAMES_INTRO_TEXT = `The names that we have for you are shown below. If you have ever
                    used another name (such as a maiden name) in connection with
                    your finances, or you have changed your name since
                    registering with Gretel, please click the **Add Previous
                    or New Name** button below. Once we have all your names
                    listed, please click the **Confirm Name History** button.`
export const NATIONAL_INSURANCE_INTRO_TEXT = `Some financial providers will have a record of your National
                    Insurance (NI) Number linked to missing assets. If you give us your NI Number, we can
                    use it to search and increase the chances of finding a match. To enter or update your
                    NI number, click the **Edit** button below.`
export const NATIONAL_INSURANCE_THIRD_PARTY_INTRO_TEXT = `Some financial providers will have a record of
                    National Insurance (NI) Numbers linked to missing assets. If you give us this Third
                    Party's NI Number, we can use it to search and increase the chances of finding a
                    match. To enter or update the NI number, click the **Edit** button below.`
export const IMPROVE_PROFILE_COMPLETED_TEXT = `Thanks for providing and confirming all your details. We are
                    regularly checking and as more and more companies work with us, the chances of finding a
                    match will increase. As soon as we find something we will notify you by email`
export const SHARED_MATCHES_INFORMATION_TEXT = `This symbol indicates that the asset is jointly registered
                    with at least one other party and no one individual may be due the total value shown.
                    Any actions relating to the asset will require the consent of all holders where
                    applicable.`
export const OBFUSCATED_VALUE_INFORMATION_TEXT = `This symbol indicates that we do not currently have the
                    up-to-date value and, in some cases, the product classification for the asset. This information
                    will be confirmed by the provider once you have completed their reconnection process.`

export const REGISTRATION_STEP_ONE = 1
export const REGISTRATION_STEP_TWO = 2
export const REGISTRATION_STEP_THREE = 3

export const WhyDoINeedThisInformationText = `In order to create a valid account through our website and to use Gretel to search for lost assets, we need you to provide some key pieces of personal information, including:

* your full name(s);
* your current address;
* a valid email address;
* a contact number (optional);
* any additional previous addresses that Gretel wasn’t able to find as part of the account creation process.

We need this information to:

i) Validate your identity and, if relevant, the identity of any person (whether alive or deceased) for whom you may be acting, together with the relevant authority enabling you to act;

ii) Procure any historic address information that may be available in order to further validate your identity and provide a complete history against which Gretel can deliver the service;

iii) Enable Gretel to undertake searches both now and in the future for any unclaimed entitlements that may be due; and

iv) Communicate with you about products and services you hold with us and where you have provided consent, tell you about products and services of selected third-party providers which we believe will be helpful to you as a consumer of financial services.


Further details about how we use your data are specifically provided in our [Privacy Notice](/privacy-notice).`

export const MANUALLY_ADD_ADDRESS_BUTTON = "Manually add address"
export const POSTCODE_LOOKUP_GENERIC_ERROR = "Please enter a postcode"
export const POSTCODE_LOOKUP_LOADING_MESSAGE = "Loading "
export const POSTCODE_LOOKUP_NO_RESULTS_MESSAGE = "No results found"

export const MY_MATCHES_TABS = [
    "Live Matches",
    "Linked Matches",
    "Closed Matches",
]
export const THIRD_PARTY_DEFAULT_TABS = ["View & Manage", "Add Third Party"]
export const THIRD_PARTY_ADDRESSES_TABS = ["View & Manage", "Add Address"]
export const THIRD_PARTY_MATCHES_TABS = ["Live Matches", "Closed Matches"]

export const CONNECTED_MATCH_REVIEW_TEXT =
    "Please review the matches below, which are linked to your current name and address, taking any action required, so that providers can stay in touch with you."
export const CONNECTED_MATCH_INFO_TEXT =
    "The following matches are linked to your current name and address. If you haven't already, please review them and take any action required, so that providers can stay in touch with you."
export const CONNECTED_MATCHES_NONE_FOUND =
    "You do not have any matches linked to your current name and address at the moment."

export const CONNECTED_MATCH_NEW_TITLE = "### NEW LINKED MATCHES"
export const CONNECTED_MATCH_CURRENT_TITLE = "### VIEWED LINKED MATCHES"
export const CONNECTED_MATCH_CLAIMED_TITLE =
    "### LINKED MATCHES BEING FOLLOWED UP"

export const CLAIMED_MATCHES_NONE_FOUND =
    "You do not currently have any matches that are flagged as not open."
export const LIVE_MATCHES_NONE_FOUND =
    "You do not have currently have any live matches."

export const MEMBERS_REGISTER_UNAVAILABLE = "members.register.unavailable"
export const MEMBERS_AUTH_UNAVAILABLE = "members.auth.unavailable"
export const EMAIL_PREFERENCES_SUCCESS_TEXT =
    "Your email preferences have been updated successfully."
export const COMMUNICATIONS_PREFERENCE_POPUP_TITLE = "Email Preferences"
export const COMMUNICATIONS_PREFERENCE_POPUP_CONTENT = `We use your email address as the main way to communicate with you. There are two different types of message that we may send to you:

**Personal Alerts**

Emails that specifically relate to account management and security, notifications of any new matches and  any updates to our Terms and Conditions.

**Gretel Updates & News**

Emails relating to new functionality, service enhancements and partners that may be of interest to you.

Although we don't think we'll be sending you more than one message a month (at most), you can still opt out of Gretel Updates & News from the My Account section of your Dashboard once you have registered.`

export const ADD_THIRD_PARTY_FORM_TITLE = `## Add Account
### Personal Details`

export const ADD_THIRD_PARTY_SUCCESS_MESSAGE =
    "Your Third Party has been registered successfully."
export const EDIT_THIRD_PARTY_SUCCESS_MESSAGE =
    "Your Third Party has been successfully updated."
export const THIRD_PARTY_ADD_ADDRESS_SUCCESS_MESSAGE =
    "The address has been added successfully. To add another address, please use the form below."

export const THIRD_PARTY_NAVIGATION_LINKS = id => [
    {
        to: `/portal/authority-for/third-parties/${id}/`,
        title: "Dashboard",
        forceUnmatch: true,
    },
    {
        to: `/portal/authority-for/third-parties/${id}/history`,
        title: "History",
        forceUnmatch: false,
    },
    {
        to: `/portal/authority-for/third-parties/${id}/matches`,
        title: "Matches",
        forceUnmatch: false,
    },
]

export const NEXT_SEARCH_TEXT = "Next search 14 days"

export const EVENT_AFFILIATE_LANDING = "evAffiliateLanding"
export const EVENT_BRAND_LANDING = "evBrandLanding"
export const EVENT_TRACKING_CONSENT = "evTrackingConsent"

export const TOKEN_REFRESH_WINDOW_START = 5
export const TOKEN_REFRESH_WINDOW_END = 1

export const ASSET_CLASS_BANK_ACCOUNT = "BANK_ACCOUNT"
export const ASSET_CLASS_CTF = "CTF"
export const ASSET_CLASS_EQUITY = "EQUITY"
export const ASSET_CLASS_INSURANCE = "INSURANCE"
export const ASSET_CLASS_INVESTMENT = "INVESTMENT"
export const ASSET_CLASS_PENSION = "PENSION"

export const COOKIE_OPT_IN = "gatsby-gdpr-google-tagmanager"
export const COOKIE_DOCUMENT_UPLOAD = "didRecentlyUploadDocument"
export const COOKIE_DISMISSED_NOTIFICATIONS = "dismissedNotifications"
