export const THIRD_PARTY_GET_TYPES = { type: "THIRD_PARTY_GET_TYPES" }
export const THIRD_PARTY_GET_TYPES_FAIL = { type: "THIRD_PARTY_GET_TYPES_FAIL" }
export const THIRD_PARTY_GET_TYPES_SUCCESS = {
    type: "THIRD_PARTY_GET_TYPES_SUCCESS",
}
export const THIRD_PARTY_GET_INDIVIDUALS = {
    type: "THIRD_PARTY_GET_INDIVIDUALS",
}
export const THIRD_PARTY_GET_INDIVIDUALS_FAIL = {
    type: "THIRD_PARTY_GET_INDIVIDUALS_FAIL",
}
export const THIRD_PARTY_GET_INDIVIDUALS_SUCCESS = {
    type: "THIRD_PARTY_GET_INDIVIDUALS_SUCCESS",
}

export const THIRD_PARTY_VIEW_INDIVIDUAL = {
    type: "THIRD_PARTY_VIEW_INDIVIDUAL",
}
export const THIRD_PARTY_VIEW_INDIVIDUAL_FAIL = {
    type: "THIRD_PARTY_VIEW_INDIVIDUAL_FAIL",
}
export const THIRD_PARTY_VIEW_INDIVIDUAL_SUCCESS = {
    type: "THIRD_PARTY_VIEW_INDIVIDUAL_SUCCESS",
}

export const THIRD_PARTY_DOWNLOAD_INDIVIDUAL_DOCUMENT = {
    type: "THIRD_PARTY_DOWNLOAD_INDIVIDUAL_DOCUMENT",
}
export const THIRD_PARTY_DOWNLOAD_INDIVIDUAL_DOCUMENT_FAIL = {
    type: "THIRD_PARTY_DOWNLOAD_INDIVIDUAL_DOCUMENT_FAIL",
}
export const THIRD_PARTY_DOWNLOAD_INDIVIDUAL_DOCUMENT_SUCCESS = {
    type: "THIRD_PARTY_DOWNLOAD_INDIVIDUAL_DOCUMENT_SUCCESS",
}

export const THIRD_PARTY_ADD_INDIVIDUAL_USER = {
    type: "THIRD_PARTY_ADD_INDIVIDUAL_USER",
}
export const THIRD_PARTY_ADD_INDIVIDUAL_USER_FAIL = {
    type: "THIRD_PARTY_ADD_INDIVIDUAL_USER_FAIL",
}
export const THIRD_PARTY_ADD_INDIVIDUAL_USER_SUCCESS = {
    type: "THIRD_PARTY_ADD_INDIVIDUAL_USER_SUCCESS",
}

export const THIRD_PARTY_UPLOAD_INDIVIDUAL_DOCUMENT = {
    type: "THIRD_PARTY_UPLOAD_INDIVIDUAL_DOCUMENT",
}
export const THIRD_PARTY_UPLOAD_INDIVIDUAL_DOCUMENT_FAIL = {
    type: "THIRD_PARTY_UPLOAD_INDIVIDUAL_DOCUMENT_FAIL",
}
export const THIRD_PARTY_UPLOAD_INDIVIDUAL_DOCUMENT_SUCCESS = {
    type: "THIRD_PARTY_UPLOAD_INDIVIDUAL_DOCUMENT_SUCCESS",
}

export const THIRD_PARTY_DELETE_INDIVIDUAL = {
    type: "THIRD_PARTY_DELETE_INDIVIDUAL",
}
export const THIRD_PARTY_DELETE_INDIVIDUAL_FAIL = {
    type: "THIRD_PARTY_DELETE_INDIVIDUAL_FAIL",
}
export const THIRD_PARTY_DELETE_INDIVIDUAL_SUCCESS = {
    type: "THIRD_PARTY_DELETE_INDIVIDUAL_SUCCESS",
}

/**
 * Third Party Addresses
 */
export const THIRD_PARTY_GET_INDIVIDUAL_ADDRESSES = {
    type: "THIRD_PARTY_GET_INDIVIDUAL_ADDRESSES",
}
export const THIRD_PARTY_GET_INDIVIDUAL_ADDRESSES_FAIL = {
    type: "THIRD_PARTY_GET_INDIVIDUAL_ADDRESSES_FAIL",
}
export const THIRD_PARTY_GET_INDIVIDUAL_ADDRESSES_SUCCESS = {
    type: "THIRD_PARTY_GET_INDIVIDUAL_ADDRESSES_SUCCESS",
}
export const THIRD_PARTY_ADD_INDIVIDUAL_ADDRESS = {
    type: "THIRD_PARTY_ADD_INDIVIDUAL_ADDRESS",
}
export const THIRD_PARTY_ADD_INDIVIDUAL_ADDRESS_FAIL = {
    type: "THIRD_PARTY_ADD_INDIVIDUAL_ADDRESS_FAIL",
}
export const THIRD_PARTY_ADD_INDIVIDUAL_ADDRESS_SUCCESS = {
    type: "THIRD_PARTY_ADD_INDIVIDUAL_ADDRESS_SUCCESS",
}
/**
 * End Third Party Addresses
 */
/**
 * Third Party Names
 */
export const THIRD_PARTY_GET_INDIVIDUAL_NAMES = {
    type: "THIRD_PARTY_GET_INDIVIDUAL_NAMES",
}
export const THIRD_PARTY_GET_INDIVIDUAL_NAMES_FAIL = {
    type: "THIRD_PARTY_GET_INDIVIDUAL_NAMES_FAIL",
}
export const THIRD_PARTY_GET_INDIVIDUAL_NAMES_SUCCESS = {
    type: "THIRD_PARTY_GET_INDIVIDUAL_NAMES_SUCCESS",
}
export const THIRD_PARTY_ADD_INDIVIDUAL_NAME = {
    type: "THIRD_PARTY_ADD_INDIVIDUAL_NAME",
}
export const THIRD_PARTY_ADD_INDIVIDUAL_NAME_FAIL = {
    type: "THIRD_PARTY_ADD_INDIVIDUAL_NAME_FAIL",
}
export const THIRD_PARTY_ADD_INDIVIDUAL_NAME_SUCCESS = {
    type: "THIRD_PARTY_ADD_INDIVIDUAL_NAME_SUCCESS",
}
export const THIRD_PARTY_EDIT_NAME = {
    type: "THIRD_PARTY_EDIT_NAME",
}
export const THIRD_PARTY_EDIT_NAME_SUCCESS = {
    type: "THIRD_PARTY_EDIT_NAME_SUCCESS",
}
export const THIRD_PARTY_EDIT_NAME_FAIL = {
    type: "THIRD_PARTY_EDIT_NAME_FAIL",
}
/**
 * End Third Party Names
 */
export const THIRD_PARTY_NI_NUMBER_UPDATE = {
    type: "THIRD_PARTY_NI_NUMBER_UPDATE",
}
export const THIRD_PARTY_NI_NUMBER_UPDATE_SUCCESS = {
    type: "THIRD_PARTY_NI_NUMBER_UPDATE_SUCCESS",
}
export const THIRD_PARTY_NI_NUMBER_UPDATE_FAIL = {
    type: "THIRD_PARTY_NI_NUMBER_UPDATE_FAIL",
}
