import { combineReducers } from "redux"
import flow from "lodash/fp/flow"
import omit from "lodash/fp/omit"

import {
    ASSETS_SEARCH_RESULTS_CLAIMED_SUCCESS,
    ASSETS_SEARCH_RESULTS_SUMMARY_SUCCESS,
    CLEAR_API_FAIL,
    GET_AVATARS,
    GET_COUNTRIES_SUCCESS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GET_SEARCH_DETAILS_SUCCESS,
    KYC_COMPLETE_SUCCESS,
    KYC_INIT_SUCCESS,
    LOGIN,
    LOGIN_FAIL,
    LOGIN_REFRESH,
    LOGIN_REFRESH_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_SUCCESS,
    MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON,
    MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON_FAIL,
    MEMBER_FAIL,
    MEMBER_REQUEST,
    MEMBER_REQUEST_SUCCESS,
    MEMBER_SET_STEP,
    MEMBER_SUCCESS,
    MEMBER_UPDATE_ADDRESS,
    MEMBER_UPDATE_ADDRESS_SUCCESS,
    MEMBER_UPDATE_AVATAR,
    MEMBER_UPDATE_AVATAR_SUCCESS,
    MEMBER_UPDATE_DATEOFBIRTH,
    MEMBER_UPDATE_DATEOFBIRTH_SUCCESS,
    MEMBER_UPDATE_EMAIL,
    MEMBER_UPDATE_EMAIL_SUCCESS,
    MEMBER_UPDATE_FIRSTNAME,
    MEMBER_UPDATE_FIRSTNAME_SUCCESS,
    MEMBER_UPDATE_LASTNAME,
    MEMBER_UPDATE_LASTNAME_SUCCESS,
    MEMBER_UPDATE_PHONE,
    MEMBER_UPDATE_PHONE_SUCCESS,
    MEMBER_UPDATE_SEARCH_REQUIRED,
    REFER_PARTNER_SUCCESS,
    REGISTRATION_COMPLETE,
    REGISTRATION_COMPLETE_SUCCESS,
    REGISTRATION_INIT,
    REGISTRATION_INIT_SUCCESS,
    REGISTRATION_ME_SUCCESS,
    REGISTRATION_REFRESH,
    REGISTRATION_REFRESH_SUCCESS,
    REGISTRATION_VERIFY_BY_CODE_SUCCESS,
    REGISTRATION_VERIFY_BY_TOKEN_SUCCESS,
    RESET_SEARCH_DETAILS,
    SCHEDULE_A_SEARCH,
    SCHEDULE_A_SEARCH_RESULTS,
    SCHEDULE_A_SEARCH_RESULTS_SUCCESS,
    SCHEDULE_A_SEARCH_STATUS_SUCCESS,
    SCHEDULE_A_SEARCH_SUCCESS,
    SET_API_FAIL,
    SET_MEMBER,
    UPDATE_NOTIFICATION,
} from "~actions/actionTypes"
import {
    GET_ADDRESSES_BY_POSTCODE,
    GET_ADDRESSES_BY_POSTCODE_FAIL,
    GET_ADDRESSES_BY_POSTCODE_SUCCESS,
} from "~actions/addressTypes"
import {
    ContactMethods,
    mapPreviousNamesToState,
    OnboardingStatuses,
    parseBoolFromApi,
    ScheduleStatuses,
    SearchStatuses,
} from "~helpers/helpers"
import {
    MEMBER_ACCOUNT_UPDATE_MARKETING_PREFERENCES_SUCCESS,
    MEMBER_CONFIRM_BANK_STATUS,
    MEMBER_CONFIRM_BANK_STATUS_FAIL,
    MEMBER_CONFIRM_BANK_STATUS_SUCCESS,
    MEMBER_UPDATE_MIDDLE_NAMES,
    MEMBER_UPDATE_MIDDLE_NAMES_SUCCESS,
    MEMBER_UPDATE_PREFERRED_NAME,
    MEMBER_UPDATE_PREFERRED_NAME_SUCCESS,
} from "~actions/memberTypes"
import { GET_MEMBER_PREVIOUS_ADDRESSES_SUCCESS } from "~actions/historicAddressTypes"

import {
    MEMBER_ACCOUNT_FIRSTNAME_UPDATE,
    MEMBERS_ACCOUNT_MIDDLE_NAMES_UPDATE,
    MEMBERS_ACCOUNT_LAST_NAME_UPDATE,
    MEMBERS_ACCOUNT_PREFERRED_NAME_UPDATE,
    MEMBERS_ACCOUNT_DATE_OF_BIRTH_UPDATE,
    MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE,
    MEMBERS_ACCOUNT_EMAIL_UPDATE,
    MEMBER_ACCOUNT_FIRSTNAME_UPDATE_SUCCESS,
    MEMBERS_ACCOUNT_MIDDLE_NAMES_UPDATE_SUCCESS,
    MEMBERS_ACCOUNT_LAST_NAME_UPDATE_SUCCESS,
    MEMBERS_ACCOUNT_PREFERRED_NAME_UPDATE_SUCCESS,
    MEMBERS_ACCOUNT_DATE_OF_BIRTH_UPDATE_SUCCESS,
    MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE_SUCCESS,
    MEMBERS_ACCOUNT_EMAIL_UPDATE_SUCCESS,
    MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS_SUCCESS,
    MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS,
    MEMBERS_NAMES_GET_NAMES_SUCCESS,
    MEMBERS_ACCOUNT_EDIT_ADDRESS_SUCCESS,
    MEMBERS_ACCOUNT_NI_NUMBER_UPDATE_SUCCESS,
    MEMBERS_ACCOUNT_NI_NUMBER_UPDATE,
    MEMBERS_ACCOUNT_PROFILE_ADDRESS_CONFIRM_SUCCESS,
    MEMBERS_ACCOUNT_PROFILE_NAMES_CONFIRM_SUCCESS,
    MEMBERS_ACCOUNT_PASSWORD_UPDATE_SUCCESS,
} from "~actions/accountTypes"
import Cookies from "js-cookie"
import {
    THIRD_PARTY_ADD_INDIVIDUAL_USER_SUCCESS,
    THIRD_PARTY_GET_INDIVIDUALS_SUCCESS,
    THIRD_PARTY_GET_INDIVIDUAL_NAMES_SUCCESS,
    THIRD_PARTY_NI_NUMBER_UPDATE_SUCCESS,
} from "~actions/thirdPartyTypes"
import { COOKIE_DISMISSED_NOTIFICATIONS } from "~helpers/constants"
import {
    GET_DSAR_CONTACT_SUCCESS,
    SEARCH_DSAR_CONTACTS,
    SEARCH_DSAR_CONTACTS_FAIL,
    SEARCH_DSAR_CONTACTS_SUCCESS,
} from "~actions/dsarTypes"
const map = require("lodash/fp/map").convert({ cap: false })

const windowGlobal = typeof window !== "undefined" && window

const initialConsentStatusState = {
    status: ScheduleStatuses.IN_PROGRESS,
    searchOperation: {},
}
const initialUploadDocumentState = {
    didRecentlyUpload: false,
}
const initialPollNamesState = {
    isPolling: false,
}
const initialDsarState = {
    isSearching: false,
    searchQuery: "",
    results: [],
    hasErrored: false,
    hasSearched: false,
    velocityRule: false,
    subjectAccessRequest: {},
}
const initialPartnersState = {
    isFetching: false,
    "page.number": 0,
    "page.size": 3,
    total: 6,
    items: [],
}
const initialResultsState = {
    items: [],
    status: SearchStatuses.SCHEDULED,
    searchOperation: {},
    isFetching: false,
    hasFetched: false,
}
const initialThirdParties = {
    items: [],
}
const initialThirdPartyMatches = {
    items: [],
    status: SearchStatuses.SCHEDULED,
    searchOperation: {},
    isFetching: false,
    hasFetched: false,
    ids: [],
}
const initialConnectedResultsState = {
    items: [],
    status: SearchStatuses.SCHEDULED,
    searchOperation: {},
    isFetching: false,
    hasFetched: false,
}
const initialClaimedResultsState = {
    items: [],
    status: SearchStatuses.SCHEDULED,
    searchOperation: {},
    isFetching: false,
    hasFetched: false,
}
const initialPreviousNames = []
const initialThirdPartyNames = []
const initialThirdPartyPreviousNames = []

const initialPreviousAddresses = []
const initialSelectedAccount = {
    id: null,
    stats: [],
    firstName: "",
    lastName: "",
}
const initialAffiliateStore = {
    affiliateName: null,
    affiliate: null,
    externalReference: null,
}
function affiliate(state = initialAffiliateStore, action) {
    const { type, payload } = action
    switch (type) {
        case "SET_AFFILIATE":
            return { ...state, ...payload }
        case LOGOUT_SUCCESS.type:
            return initialAffiliateStore
        default:
            return state
    }
}
function uploadDocument(state = initialUploadDocumentState, action) {
    const { type } = action
    switch (type) {
        case "RECENTLY_UPLOADED":
            return { ...state, didRecentlyUpload: true }
        case "EXPIRED_UPLOAD":
            return { ...state, didRecentlyUpload: false }
        default:
            return state
    }
}
function thirdPartyAccounts(state = initialThirdParties, action) {
    const { type, payload } = action
    switch (type) {
        case THIRD_PARTY_NI_NUMBER_UPDATE_SUCCESS.type:
            return {
                ...state,
                items: state.items.map(item =>
                    item.id === action.payload.data.data.id
                        ? {
                              ...item,
                              attributes: {
                                  ...action.payload.data.data.attributes,
                                  niNumber:
                                      action.payload.data.data.attributes
                                          .niNumber,
                              },
                          }
                        : item
                ),
            }
        case THIRD_PARTY_GET_INDIVIDUALS_SUCCESS.type:
            return {
                ...state,
                ids: [
                    ...new Set(
                        payload.data.data.map(thirdParty => thirdParty.id)
                    ),
                ],
                items: payload.data.data.map(thirdParty => {
                    let addressIds = [
                        ...new Set(
                            thirdParty.relationships?.addresses?.map(
                                address => address.data.id
                            )
                        ),
                    ]
                    return {
                        ...thirdParty,
                        attributes: {
                            ...thirdParty.attributes,
                            niNumber: thirdParty.attributes.hasOwnProperty(
                                "niNumber"
                            )
                                ? thirdParty.attributes.niNumber
                                : null,
                        },
                        addressCount:
                            thirdParty?.relationships?.addresses?.length || 0,
                        addresses:
                            payload?.data?.included?.filter(
                                include =>
                                    include.type === "address" &&
                                    addressIds.includes(include.id)
                            ) || [],
                    }
                }),
            }
        case THIRD_PARTY_ADD_INDIVIDUAL_USER_SUCCESS.type:
            return { ...state, items: [...state.items, payload.data.data] }
        default:
            return state
    }
}
function thirdPartyMatches(state = initialThirdPartyMatches, action) {
    switch (action.type) {
        case SCHEDULE_A_SEARCH_RESULTS_SUCCESS.type:
            let thirdPartyResults =
                action.payload.data.data.attributes.results.filter(item =>
                    item.hasOwnProperty("thirdPartyId")
                )
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                items:
                    organiseSearchResults(
                        thirdPartyResults,
                        action.payload.data.included,
                        false
                    ) || [],
                included: action.payload.data.included,
                ids: action?.payload?.data?.included
                    ? action.payload.data.included
                          .filter(i => i.type === "thirdParty")
                          .map(({ id }) => id)
                    : [],
            }
        case RESET_SEARCH_DETAILS.type:
            return {
                ...state,
                singleMatch: {
                    register: {},
                    matches: [],
                },
            }
        case LOGOUT_SUCCESS.type:
            return initialThirdPartyMatches
        default:
            return state
    }
}
const initialNotificationsState = {
    dismissed: Cookies.get(COOKIE_DISMISSED_NOTIFICATIONS)
        ? JSON.parse(Cookies.get(COOKIE_DISMISSED_NOTIFICATIONS))
        : [],
    title: "Offers & Notifications",
    items: [],
}

const initialAvatarsState = {
    items: [
        {
            id: 1,
            avatarUrl: "../images/avatars/01a.svg ",
            altText: "avatar-image",
        },
        {
            id: 2,
            avatarUrl: "../images/avatars/01c.svg ",
            altText: "avatar-image",
        },
        {
            id: 3,
            avatarUrl: "../images/avatars/02b.svg ",
            altText: "avatar-image",
        },
        {
            id: 4,
            avatarUrl: "../images/avatars/03a.svg ",
            altText: "avatar-image",
        },
        {
            id: 5,
            avatarUrl: "../images/avatars/03c.svg ",
            altText: "avatar-image",
        },
        {
            id: 6,
            avatarUrl: "../images/avatars/04b.svg ",
            altText: "avatar-image",
        },
        {
            id: 7,
            avatarUrl: "../images/avatars/05a.svg ",
            altText: "avatar-image",
        },
        {
            id: 8,
            avatarUrl: "../images/avatars/05c.svg ",
            altText: "avatar-image",
        },
        {
            id: 9,
            avatarUrl: "../images/avatars/06b.svg ",
            altText: "avatar-image",
        },
        {
            id: 10,
            avatarUrl: "../images/avatars/07a.svg ",
            altText: "avatar-image",
        },
        {
            id: 11,
            avatarUrl: "../images/avatars/07c.svg",
            altText: "avatar-image",
        },
        {
            id: 12,
            avatarUrl: "../images/avatars/01b.svg ",
            altText: "avatar-image",
        },
        {
            id: 13,
            avatarUrl: "../images/avatars/02a.svg ",
            altText: "avatar-image",
        },
        {
            id: 14,
            avatarUrl: "../images/avatars/02c.svg ",
            altText: "avatar-image",
        },
        {
            id: 15,
            avatarUrl: "../images/avatars/03b.svg ",
            altText: "avatar-image",
        },
        {
            id: 16,
            avatarUrl: "../images/avatars/04a.svg ",
            altText: "avatar-image",
        },
        {
            id: 17,
            avatarUrl: "../images/avatars/04c.svg ",
            altText: "avatar-image",
        },
        {
            id: 18,
            avatarUrl: "../images/avatars/05b.svg ",
            altText: "avatar-image",
        },
        {
            id: 19,
            avatarUrl: "../images/avatars/06a.svg ",
            altText: "avatar-image",
        },
        {
            id: 20,
            avatarUrl: "../images/avatars/06c.svg ",
            altText: "avatar-image",
        },
        {
            id: 21,
            avatarUrl: "../images/avatars/07b.svg",
            altText: "avatar-image",
        },
    ],
    isFetching: false,
    hasErrored: false,
}
const initialCountriesState = []
const initialLookupAddresses = {
    items: [],
    isFetching: false,
}
const initialMemberState = {
    isAuthenticated: false,
    isRefreshingToken: false,
    middleNames: "",
    preferredName: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    jwtToken: false,
    password: "",
    phoneNumber: "",
    niNumber: "",
    avatar: {},
    confirmationRequired: false,
    contactAddress: {
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
        postcode: "",
        country: "",
        countryName: "",
    },
    isFetching: false,
    previousAddressesRequired: true,
}
function partners(state = initialPartnersState, action) {
    const { type, payload, meta } = action
    switch (type) {
        case "RESET_PARTNERS":
            return initialPartnersState
        case GET_PARTNERS.type:
            return { ...state, isFetching: true }
        case REFER_PARTNER_SUCCESS.type:
            return {
                ...state,
                isFetching: false,
                items: state.items.map(partner =>
                    partner.id ===
                    meta.previousAction.payload.request.url.substring(
                        meta.previousAction.payload.request.url.lastIndexOf(
                            "/"
                        ) + 1
                    )
                        ? {
                              ...partner,
                              attributes: {
                                  ...partner.attributes,
                                  referred: true,
                              },
                          }
                        : partner
                ),
            }
        case GET_PARTNERS_SUCCESS.type:
            return {
                ...state,
                isFetching: false,
                "page.number": JSON.parse(payload.data.data.attributes.hasMore)
                    ? state["page.number"] + 1
                    : state["page.number"],
                total: payload.data.data.attributes.total,
                ...payload.data.data,
                items: [
                    ...state.items,
                    ...payload.data.included.map(partner => ({
                        ...partner,
                        attributes: {
                            ...partner.attributes,
                            referred: parseBoolFromApi(
                                "referred",
                                partner.attributes
                            ),
                        },
                    })),
                ],
            }
        case LOGOUT_SUCCESS.type:
            return initialPartnersState
        default:
            return state
    }
}
function notifications(state = initialNotificationsState, action) {
    const { payload } = action

    switch (action.type) {
        case GET_NOTIFICATIONS_SUCCESS.type:
            return {
                ...state,
                items: payload.data.data.map(notification => {
                    return {
                        ...notification,
                        attributes: {
                            ...notification.attributes,
                            persistent: notification.attributes.hasOwnProperty(
                                "persistent"
                            )
                                ? JSON.parse(
                                      notification.attributes.persistent
                                  ) === true
                                : false,
                        },
                    }
                }),
            }
        case UPDATE_NOTIFICATION.type:
            Cookies.set(
                COOKIE_DISMISSED_NOTIFICATIONS,
                JSON.stringify([...state.dismissed, payload.notificationId]),
                {
                    expires: 90,
                }
            )
            return {
                ...state,
                dismissed: [...state.dismissed, payload.notificationId],
            }
        case LOGOUT_SUCCESS.type:
            return initialNotificationsState
        default:
            return state
    }
}
function avatars(state = initialAvatarsState, action) {
    switch (action.type) {
        case GET_AVATARS.type:
            return {
                ...state,
            }

        default:
            return state
    }
}
const initialOnboarding = {
    registration: {
        hasWiped: false,
    },
}
function onboarding(state = initialOnboarding, action) {
    switch (action.type) {
        case "PURGE_REGISTRATION":
            return {
                ...state,
                registration: { hasWiped: true },
            }
        case "RESET_REGISTRATION":
            return {
                ...state,
                registration: { hasWiped: false },
            }
        case LOGOUT_SUCCESS.type:
            return initialOnboarding
        default:
            return state
    }
}
function previousAddresses(state = initialPreviousAddresses, action) {
    switch (action.type) {
        case GET_MEMBER_PREVIOUS_ADDRESSES_SUCCESS.type:
            return action.payload.data.data
        case LOGOUT_SUCCESS.type:
            return initialPreviousAddresses
        default:
            return state
    }
}

function previousNames(state = initialPreviousNames, action) {
    switch (action.type) {
        case MEMBERS_NAMES_GET_NAMES_SUCCESS.type:
            return mapPreviousNamesToState(action)
        case "REMOVE_PREVIOUS_NAME":
            return state.filter(name => action.payload !== name.id)
        case LOGOUT_SUCCESS.type:
            return initialPreviousNames
        default:
            return state
    }
}
function thirdPartyNames(state = initialThirdPartyNames, action) {
    switch (action.type) {
        case THIRD_PARTY_GET_INDIVIDUALS_SUCCESS.type:
            return action.payload.data.data.map(res => ({
                ...res,
                attributes: { ...res.attributes, current: false },
            }))
        case "REMOVE_THIRD_PARTY_NAME":
            return state.filter(name => action.payload !== name.id)
        case LOGOUT_SUCCESS.type:
            return initialThirdPartyNames
        default:
            return state
    }
}
function thirdPartyPreviousNames(
    state = initialThirdPartyPreviousNames,
    action
) {
    switch (action.type) {
        case THIRD_PARTY_GET_INDIVIDUAL_NAMES_SUCCESS.type:
            return mapPreviousNamesToState(action).sort((x, y) =>
                x.attributes.current === y.attributes.current
                    ? 0
                    : x.attributes.current
                    ? -1
                    : 1
            )
        case "REMOVE_THIRD_PARTY_PREVIOUS_NAME":
            return state.filter(name => action.payload !== name.id)
        case LOGOUT_SUCCESS.type:
            return initialThirdPartyPreviousNames
        default:
            return state
    }
}
function countries(state = initialCountriesState, action) {
    switch (action.type) {
        case GET_COUNTRIES_SUCCESS.type:
            return [
                ...action.payload.data.data.map(x => ({
                    value: x.id,
                    label: x.attributes.displayName,
                })),
            ]
        default:
            return state
    }
}
const parseSearchBreakdown = (
    data,
    included,
    allData,
    excludeThirdParty = true
) => {
    return flow(
        map(assetClass => ({
            ...assetClass,
            matches: map(singleMatch => {
                return included.find(include => include.id === singleMatch)
            })(assetClass.matches),
            redactedMatches: map(
                singleMatch =>
                    included.find(include => include?.id === singleMatch) || {}
            )(assetClass.redactedMatches),
        })),
        map(assetClass => {
            return {
                ...assetClass,
                matches: map(singleMatch => {
                    return {
                        ...singleMatch,
                        attributes: {
                            ...singleMatch.attributes,
                            obfuscatedValue: parseBoolFromApi(
                                "obfuscatedValue",
                                singleMatch.attributes
                            ),
                        },
                        relationships: map(singleRelationship =>
                            included.find(
                                i => i.id === singleRelationship.data.id
                            )
                        )(
                            excludeThirdParty
                                ? omit("thirdParty", singleMatch.relationships)
                                : singleMatch.relationships
                        ),
                    }
                })(assetClass.matches),
                redactedMatches: map(singleMatch => {
                    return {
                        ...singleMatch,
                        relationships: map(
                            singleRelationship =>
                                included.find(
                                    i => i?.id === singleRelationship?.data?.id
                                ) || {}
                        )(
                            excludeThirdParty
                                ? omit(singleMatch.relationships, "thirdParty")
                                : singleMatch.relationships
                        ),
                    }
                })(assetClass.redactedMatches),
            }
        })
    )(data)
}
const organiseSearchResults = (results, included, excludeThirdParty = true) => {
    if (excludeThirdParty) {
        let data = []
        const NEW =
            parseSearchBreakdown(
                results?.breakdown?.NEW,
                included,
                data,
                excludeThirdParty
            ) ?? null
        const CURRENT =
            parseSearchBreakdown(
                results?.breakdown?.CURRENT,
                included,
                data,
                excludeThirdParty
            ) ?? null
        const CLAIMING =
            parseSearchBreakdown(
                results?.breakdown?.CLAIMING,
                included,
                data,
                excludeThirdParty
            ) ?? null
        return {
            NEW: NEW || [],
            CURRENT: CURRENT || [],
            CLAIMING: CLAIMING || [],
        }
    } else {
        let data = []
        return results.map(result => {
            return {
                thirdPartyId: result.thirdPartyId,
                estimatedValue: result.estimatedValue,
                estimatedValueCompact: result.estimatedValueCompact,
                estimatedValuesByStatus: result.estimatedValuesByStatus,
                hasDisconnected: parseBoolFromApi("hasDisconnected", result),
                hasMatches: parseBoolFromApi("hasMatches", result),
                hasRedacted: parseBoolFromApi("hasRedacted", result),
                hasShared: parseBoolFromApi("hasShared", result),
                hasObfuscatedValue: parseBoolFromApi(
                    "hasObfuscatedValue",
                    result
                ),

                matches: result.matches,
                items: {
                    NEW:
                        parseSearchBreakdown(
                            result?.breakdown?.NEW,
                            included,
                            data,
                            excludeThirdParty
                        ) ?? null,
                    CURRENT:
                        parseSearchBreakdown(
                            result?.breakdown.CURRENT,
                            included,
                            data,
                            excludeThirdParty
                        ) ?? null,
                    CLAIMING:
                        parseSearchBreakdown(
                            result?.breakdown?.CLAIMING,
                            included,
                            data,
                            excludeThirdParty
                        ) ?? null,
                },
            }
        })
    }
}
const organiseClaimedSearchResults = (
    results,
    included,
    excludeThirdParty = true
) => {
    if (excludeThirdParty) {
        let data = []
        const CLAIMED =
            parseSearchBreakdown(
                results?.breakdown?.CLAIMED,
                included,
                data,
                excludeThirdParty
            ) ?? null
        const OBSOLETE =
            parseSearchBreakdown(
                results?.breakdown?.OBSOLETE,
                included,
                data,
                excludeThirdParty
            ) ?? null
        return { CLAIMED: CLAIMED, OBSOLETE: OBSOLETE }
    } else {
        let data = []
        return results.map(result => {
            return {
                thirdPartyId: result.thirdPartyId,
                estimatedValue: result.estimatedValue,
                estimatedValueCompact: result.estimatedValueCompact,
                estimatedValuesByStatus: result.estimatedValuesByStatus,
                hasDisconnected: parseBoolFromApi("hasDisconnected", result),
                hasMatches: parseBoolFromApi("hasMatches", result),
                hasRedacted: parseBoolFromApi("hasRedacted", result),
                matches: result.matches,
                items: {
                    CLAIMED:
                        parseSearchBreakdown(
                            result?.breakdown?.CLAIMED,
                            included,
                            data,
                            excludeThirdParty
                        ) ?? null,
                    OBSOLETE:
                        parseSearchBreakdown(
                            result?.breakdown.OBSOLETE,
                            included,
                            data,
                            excludeThirdParty
                        ) ?? null,
                },
            }
        })
    }
}
const organiseConnectedSearchResults = (
    results,
    included,
    excludeThirdParty = true
) => {
    if (excludeThirdParty) {
        let data = []
        const NEW =
            parseSearchBreakdown(
                results?.breakdown?.NEW,
                included,
                data,
                excludeThirdParty
            ) ?? null
        const CURRENT =
            parseSearchBreakdown(
                results?.breakdown?.CURRENT,
                included,
                data,
                excludeThirdParty
            ) ?? null
        const CLAIMING =
            parseSearchBreakdown(
                results?.breakdown?.CLAIMING,
                included,
                data,
                excludeThirdParty
            ) ?? null
        return { NEW: NEW, CURRENT: CURRENT, CLAIMING: CLAIMING }
    } else {
    }
}
function consentStatusResults(state = initialConsentStatusState, action) {
    switch (action.type) {
        case MEMBER_CONFIRM_BANK_STATUS.type:
            return {
                ...state,
            }
        case MEMBER_CONFIRM_BANK_STATUS_SUCCESS.type:
            return {
                ...state,
                status: action.payload.data.data.attributes.status,
                searchOperation: { ...action.payload.data.data },
                included: { ...action.payload.data.included },
            }
        case MEMBER_CONFIRM_BANK_STATUS_FAIL.type:
            return {
                ...state,
            }
        case LOGOUT_SUCCESS.type:
            return initialConsentStatusState
        default:
            return state
    }
}
function connectedResults(state = initialConnectedResultsState, action) {
    switch (action.type) {
        case MEMBER_ASSETS_SEARCH_CONNECTED_RESULTS_SUCCESS.type:
            let firstPartyResults =
                action.payload?.data?.data?.attributes?.results?.find(
                    item => !item.hasOwnProperty("thirdPartyId")
                ) || {}
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                matches: action.payload.data.data,
                items: organiseConnectedSearchResults(
                    firstPartyResults,
                    action.payload.data?.included,
                    true
                ),
                connected:
                    action.payload.data?.data?.attributes?.connected || [],
                included: action.payload.data.included
                    ? action.payload.data.included.filter(
                          i => i.type !== "thirdParty"
                      )
                    : [],
                estimatedValuesByStatus:
                    firstPartyResults?.estimatedValuesByStatus,
                estimatedValue: firstPartyResults?.estimatedValue,
                estimatedValueCompact:
                    firstPartyResults?.estimatedValueCompact || 0,
                hasMatches: parseBoolFromApi("hasMatches", firstPartyResults),
                hasShared: parseBoolFromApi("hasShared", firstPartyResults),
                hasObfuscatedValue: parseBoolFromApi(
                    "hasObfuscatedValue",
                    firstPartyResults
                ),
                hasRedacted: parseBoolFromApi("hasRedacted", firstPartyResults),
                hasConnected: parseBoolFromApi(
                    "hasConnected",
                    firstPartyResults
                ),
                hasDisconnected: parseBoolFromApi(
                    "hasDisconnected",
                    firstPartyResults
                ),
                requested: action.payload.data.data.attributes.requested,
            }
        case LOGOUT_SUCCESS.type:
            return initialConnectedResultsState
        default:
            return state
    }
}
function thirdPartyClaimedResults(state = initialClaimedResultsState, action) {
    switch (action.type) {
        case ASSETS_SEARCH_RESULTS_CLAIMED_SUCCESS.type:
            let thirdPartyResults =
                action.payload.data.data.attributes.results.filter(item =>
                    item.hasOwnProperty("thirdPartyId")
                )
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                items: organiseClaimedSearchResults(
                    thirdPartyResults,
                    action.payload.data?.included,
                    false
                ),
                connected:
                    action.payload.data?.data?.attributes?.connected || [],
                included: action.payload.data.included
                    ? action.payload.data.included.filter(
                          i => i.type === "thirdParty"
                      )
                    : [],
                estimatedValuesByStatus:
                    thirdPartyResults?.estimatedValuesByStatus,
                estimatedValue: thirdPartyResults?.estimatedValue,
                estimatedValueCompact:
                    thirdPartyResults?.estimatedValueCompact || 0,
                hasMatches: parseBoolFromApi(
                    "hasMatches",
                    thirdPartyResults,
                    true
                ),
                hasShared: parseBoolFromApi(
                    "hasShared",
                    thirdPartyResults,
                    true
                ),
                hasObfuscatedValue: parseBoolFromApi(
                    "hasObfuscatedValue",
                    thirdPartyResults,
                    true
                ),
                hasRedacted: parseBoolFromApi(
                    "hasRedacted",
                    thirdPartyResults,
                    true
                ),
                hasConnected: parseBoolFromApi(
                    "hasConnected",
                    thirdPartyResults,
                    true
                ),
                hasDisconnected: parseBoolFromApi(
                    "hasDisconnected",
                    thirdPartyResults,
                    true
                ),
                requested: action.payload.data.data.attributes.requested,
            }
        case MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON.type:
            return initialClaimedResultsState
        case MEMBER_ASSETS_SEARCH_NON_CURRENT_RESULTS_PERSON_FAIL.type:
            return initialClaimedResultsState
        case LOGOUT_SUCCESS.type:
            return initialClaimedResultsState
        default:
            return state
    }
}
function claimedResults(state = initialClaimedResultsState, action) {
    switch (action.type) {
        case ASSETS_SEARCH_RESULTS_CLAIMED_SUCCESS.type:
            let firstPartyResults =
                action.payload?.data?.data?.attributes?.results?.find(
                    item => !item.hasOwnProperty("thirdPartyId")
                ) || {}
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                items: organiseClaimedSearchResults(
                    firstPartyResults,
                    action.payload.data?.included,
                    true
                ),
                connected:
                    action.payload.data?.data?.attributes?.connected || [],
                included: action.payload.data.included
                    ? action.payload.data.included.filter(
                          i => i.type !== "thirdParty"
                      )
                    : [],
                estimatedValuesByStatus:
                    firstPartyResults?.estimatedValuesByStatus,
                estimatedValue: firstPartyResults?.estimatedValue,
                estimatedValueCompact:
                    firstPartyResults?.estimatedValueCompact || 0,
                hasMatches: parseBoolFromApi("hasMatches", firstPartyResults),
                hasShared: parseBoolFromApi("hasShared", firstPartyResults),
                hasObfuscatedValue: parseBoolFromApi(
                    "hasObfuscatedValue",
                    firstPartyResults
                ),
                hasRedacted: parseBoolFromApi("hasRedacted", firstPartyResults),
                hasConnected: parseBoolFromApi(
                    "hasConnected",
                    firstPartyResults
                ),
                hasDisconnected: parseBoolFromApi(
                    "hasDisconnected",
                    firstPartyResults
                ),
                requested: action.payload.data.data.attributes.requested,
            }
        case LOGOUT_SUCCESS.type:
            return initialClaimedResultsState
        default:
            return state
    }
}

function firstPartySearchResults(state = initialResultsState, action) {
    switch (action.type) {
        case ASSETS_SEARCH_RESULTS_SUMMARY_SUCCESS.type:
            return {
                ...state,
                hasMatches: action.payload.data.data.length > 0 || false,
            }
        case SCHEDULE_A_SEARCH.type:
            return {
                ...state,
                status: SearchStatuses.SCHEDULED,
                isFetching: true,
            }
        case SCHEDULE_A_SEARCH_SUCCESS.type:
            return {
                ...state,
                status: SearchStatuses.SCHEDULED,
                searchOperation: { ...action.payload.data.data },
                isFetching: true,
            }
        case SCHEDULE_A_SEARCH_STATUS_SUCCESS.type:
            return {
                ...state,
                status: action.payload.data.data.attributes.searchStatus,
            }
        case SCHEDULE_A_SEARCH_RESULTS.type:
            return {
                ...state,
                isFetching: true,
                hasFetched: false,
                matches: [],
                estimatedTotal: 0.0,
                items: [],
            }
        case SCHEDULE_A_SEARCH_RESULTS_SUCCESS.type:
            //This will pluck the firstParty Breakdown out
            let firstPartyResults =
                action.payload?.data?.data?.attributes?.results?.find(
                    item => !item.hasOwnProperty("thirdPartyId")
                ) || {}
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                items:
                    organiseSearchResults(
                        firstPartyResults,
                        action.payload.data?.included,
                        true
                    ) ?? [],
                connected:
                    action.payload.data?.data?.attributes?.connected || [],
                included: action.payload.data.included
                    ? action.payload.data.included.filter(
                          i => i.type !== "thirdParty"
                      )
                    : [],
                estimatedValuesByStatus:
                    firstPartyResults?.estimatedValuesByStatus,
                estimatedValue: firstPartyResults?.estimatedValue,
                estimatedValueCompact:
                    firstPartyResults?.estimatedValueCompact || 0,
                hasMatches: parseBoolFromApi("hasMatches", firstPartyResults),
                hasShared: parseBoolFromApi("hasShared", firstPartyResults),
                hasObfuscatedValue: parseBoolFromApi(
                    "hasObfuscatedValue",
                    firstPartyResults
                ),
                hasRedacted: parseBoolFromApi("hasRedacted", firstPartyResults),
                hasConnected: parseBoolFromApi(
                    "hasConnected",
                    firstPartyResults
                ),
                hasDisconnected: parseBoolFromApi(
                    "hasDisconnected",
                    firstPartyResults
                ),
                requested: action.payload.data.data.attributes.requested,
            }
        case GET_SEARCH_DETAILS_SUCCESS.type:
            return {
                ...state,
                singleMatch: {
                    register: {
                        ...action.payload.data.data,
                        attributes: {
                            ...action.payload.data.data.attributes,
                            shared: parseBoolFromApi(
                                "shared",
                                action.payload.data.data.attributes
                            ),
                            obfuscatedValue: parseBoolFromApi(
                                "obfuscatedValue",
                                action.payload.data.data.attributes
                            ),
                            connected: parseBoolFromApi(
                                "connected",
                                action.payload.data.data.attributes
                            ),
                            claimed: parseBoolFromApi(
                                "claimed",
                                action.payload.data.data.attributes
                            ),
                        },
                    },
                    matches: action.payload.data.included,
                },
            }
        case RESET_SEARCH_DETAILS.type:
            return {
                ...state,
                singleMatch: {
                    register: {},
                    matches: [],
                },
            }
        case LOGOUT_SUCCESS.type:
            return initialResultsState
        default:
            return state
    }
}
function lookupAddresses(state = initialLookupAddresses, action) {
    switch (action.type) {
        case GET_ADDRESSES_BY_POSTCODE.type:
            return {
                ...state,
                isFetching: true,
            }
        case GET_ADDRESSES_BY_POSTCODE_SUCCESS.type:
            return {
                ...state,
                items: action.payload.data.data.map(x => ({
                    value: x.attributes,
                    label: x.attributes.address1,
                })),
                isFetching: false,
            }
        case GET_ADDRESSES_BY_POSTCODE_FAIL.type:
            return {
                ...state,
                isFetching: false,
            }
        case LOGOUT_SUCCESS.type:
            return initialLookupAddresses
        default:
            return state
    }
}
function member(state = initialMemberState, action) {
    switch (action.type) {
        case "PREVIOUS_ADDRESSES_COMPLETED":
            return {
                ...state,
                previousAddressesRequired: false,
            }

        case MEMBERS_ACCOUNT_PASSWORD_UPDATE_SUCCESS.type:
            return {
                ...state,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
            }
        case REGISTRATION_INIT_SUCCESS.type:
            return {
                ...state,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
            }

        case REGISTRATION_VERIFY_BY_TOKEN_SUCCESS.type:
            return {
                ...state,
                ...action.payload.data.data.attributes,
                isAuthenticated: true,
                previousAddressesRequired:
                    action.payload.data.data.attributes.hasOwnProperty(
                        "previousAddressesRequired"
                    )
                        ? JSON.parse(
                              action.payload.data.data.attributes
                                  .previousAddressesRequired
                          ) === true
                        : false,
            }
        case KYC_INIT_SUCCESS.type:
            return {
                ...state,
                sessionId: action.payload.data.data.id,
                previousAddressesRequired: false,
            }
        case KYC_COMPLETE_SUCCESS.type:
            return {
                ...state,
                ...action.payload.data.data.attributes,
                onboardingStatus: null,
                isAuthenticated: true,
                previousAddressesRequired: false,
            }
        case "KYC_SKIP":
            return {
                ...state,
                isAuthenticated: true,
                previousAddressesRequired: false,
            }
        case REGISTRATION_VERIFY_BY_CODE_SUCCESS.type:
            return {
                ...state,
                ...action.payload.data.data.attributes,
                isAuthenticated: true,
                previousAddressesRequired:
                    action.payload.data.data.attributes.hasOwnProperty(
                        "previousAddressesRequired"
                    )
                        ? JSON.parse(
                              action.payload.data.data.attributes
                                  .previousAddressesRequired
                          ) === true
                        : false,
            }
        case MEMBER_REQUEST.type:
            return {
                ...state,
                isFetching: true,
            }
        case MEMBER_REQUEST_SUCCESS.type:
            return {
                ...state,
                ...action.payload.data.data.attributes,
                marketing: action.payload.data.data.attributes.hasOwnProperty(
                    "marketing"
                )
                    ? JSON.parse(
                          action.payload.data.data.attributes.marketing
                      ) === true
                    : false,
                hasMatches: action.payload.data.data.attributes.hasOwnProperty(
                    "hasMatches"
                )
                    ? JSON.parse(
                          action.payload.data.data.attributes.hasMatches
                      ) === true
                    : false,
                previousAddressesRequired:
                    action.payload.data.data.attributes.hasOwnProperty(
                        "previousAddressesRequired"
                    )
                        ? JSON.parse(
                              action.payload.data.data.attributes
                                  .previousAddressesRequired
                          ) === true
                        : false,
                searchRequired:
                    action.payload.data.data.attributes.hasOwnProperty(
                        "searchRequired"
                    )
                        ? JSON.parse(
                              action.payload.data.data.attributes.searchRequired
                          ) === true
                        : false,
                contactAddress: {
                    ...action.payload.data.included[0].attributes,
                },
            }
        case SET_MEMBER.type:
            return {
                ...state,
                ...action.data,
                onboardingStatus: action.data.hasOwnProperty("onboardingStatus")
                    ? action.data.onboardingStatus
                    : false,
                searchRequired: action.data.hasOwnProperty("searchRequired")
                    ? JSON.parse(action.data.searchRequired) === true
                    : false,
                previousAddressesRequired: action.data.hasOwnProperty(
                    "previousAddressesRequired"
                )
                    ? JSON.parse(action.data.previousAddressesRequired) === true
                    : false,
                isAuthenticated: state.isAuthenticated,
            }
        case MEMBER_UPDATE_SEARCH_REQUIRED.type:
            return {
                ...state,
                ...action.data,
            }

        case MEMBER_FAIL.type:
            return {
                ...state,
                isFetching: false,
            }
        case MEMBER_SUCCESS.type:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                ...action.payload.data.data.attributes,
            }
        case MEMBER_UPDATE_FIRSTNAME.type:
            return {
                ...state,
                firstName: action.payload.request.data.data.attributes.value,
            }
        case MEMBER_UPDATE_FIRSTNAME_SUCCESS.type:
            return {
                ...state,
                firstName: action.payload.data.data.attributes.firstName,
                preferredName:
                    action.payload.data.data.attributes.preferredName,
            }
        case MEMBER_UPDATE_LASTNAME.type:
            return {
                ...state,
                lastName: action.payload.request.data.data.attributes.value,
            }
        case MEMBER_UPDATE_LASTNAME_SUCCESS.type:
            return {
                ...state,
                lastName: action.payload.data.data.attributes.lastName,
            }
        case MEMBER_UPDATE_MIDDLE_NAMES.type:
            return {
                ...state,
                middleNames: action.payload.request.data.data.attributes.value,
            }
        case MEMBER_UPDATE_MIDDLE_NAMES_SUCCESS.type:
            return {
                ...state,
                middleNames: action.payload.data.data.attributes.middleNames,
            }
        case MEMBER_UPDATE_PREFERRED_NAME.type:
            return {
                ...state,
                preferredName:
                    action.payload.request.data.data.attributes.value,
            }
        case MEMBER_UPDATE_PREFERRED_NAME_SUCCESS.type:
            return {
                ...state,
                preferredName:
                    action.payload.data.data.attributes.preferredName,
            }
        case MEMBER_UPDATE_PHONE.type:
            return {
                ...state,
                phoneNumber: action.payload.request.data.data.attributes.value,
            }
        case MEMBER_UPDATE_PHONE_SUCCESS.type:
            return {
                ...state,
                phoneNumber: action.payload.data.data.attributes.phoneNumber,
            }

        case MEMBER_UPDATE_ADDRESS.type:
            return {
                ...state,
                contactAddress: action.payload.request.data.data.attributes,
            }
        case MEMBER_UPDATE_ADDRESS_SUCCESS.type:
            return {
                ...state,
                contactAddress: action.payload.data.included[0].attributes,
            }
        case MEMBER_UPDATE_EMAIL.type:
            return {
                ...state,
                email: action.payload.request.data.data.attributes.value,
            }
        case MEMBER_UPDATE_EMAIL_SUCCESS.type:
            return {
                ...state,
                email: action.payload.data.data.attributes.email,
            }
        case MEMBER_UPDATE_DATEOFBIRTH.type:
            return {
                ...state,
                dateOfBirth: action.payload.request.data.data.attributes.value,
            }
        case MEMBER_UPDATE_DATEOFBIRTH_SUCCESS.type:
            return {
                ...state,
                dateOfBirth: action.payload.data.data.attributes.dateOfBirth,
            }
        case MEMBER_UPDATE_AVATAR.type:
            return {
                ...state,
                avatar: action.payload.request.data.data.attributes.value,
            }
        case MEMBER_UPDATE_AVATAR_SUCCESS.type:
            return {
                ...state,
            }
        case LOGIN_REFRESH.type:
            return {
                ...state,
                isFetching: true,
                isRefreshingToken: true,
            }
        case LOGIN_REFRESH_SUCCESS.type:
            return {
                ...state,
                isFetching: false,
                isRefreshingToken: false,
            }
        case LOGIN_SUCCESS.type:
            return {
                ...state,
                isAuthenticated:
                    action.payload.data.data.attributes.hasOwnProperty(
                        "onboardingStatus"
                    )
                        ? action.payload.data.data.attributes !==
                          OnboardingStatuses.VERIFICATION
                        : true,
                previousAddressesRequired:
                    action.payload.data.data.attributes.hasOwnProperty(
                        "previousAddressesRequired"
                    )
                        ? JSON.parse(
                              action.payload.data.data.attributes
                                  .previousAddressesRequired
                          ) === true
                        : false,
            }
        case LOGOUT_SUCCESS.type:
            windowGlobal && windowGlobal.localStorage.clear()
            return {
                ...initialMemberState,
                isAuthenticated: false,
            }
        // MyAccount reductions
        case MEMBER_ACCOUNT_FIRSTNAME_UPDATE.type:
            return {
                ...state,
                //TODO: Get the fields added into here
            }
        case MEMBER_ACCOUNT_FIRSTNAME_UPDATE_SUCCESS.type:
            return {
                ...state,
                firstName: action.payload.data.data.attributes.firstName,
            }
        case MEMBERS_ACCOUNT_MIDDLE_NAMES_UPDATE.type:
            return {
                ...state,
                //TODO: Get the fields added into here
            }
        case MEMBERS_ACCOUNT_MIDDLE_NAMES_UPDATE_SUCCESS.type:
            return {
                ...state,
                middleNames: action.payload.data.data.attributes.middleNames,
            }
        case MEMBERS_ACCOUNT_LAST_NAME_UPDATE.type:
            return {
                ...state,
                //TODO: Get the
            }
        case MEMBERS_ACCOUNT_LAST_NAME_UPDATE_SUCCESS.type:
            return {
                ...state,
                lastName: action.payload.data.data.attributes.lastName,
            }
        case MEMBERS_ACCOUNT_PREFERRED_NAME_UPDATE.type:
            return {
                ...state,
                preferredName:
                    action.payload.request.data.data.attributes.preferredName,
            }
        case MEMBERS_ACCOUNT_PREFERRED_NAME_UPDATE_SUCCESS.type:
            return {
                ...state,
                preferredName:
                    action.payload.data.data.attributes.preferredName,
            }
        case MEMBERS_ACCOUNT_DATE_OF_BIRTH_UPDATE.type:
            return {
                ...state,
                //TODO: Get the fields added into here
            }
        case MEMBERS_ACCOUNT_DATE_OF_BIRTH_UPDATE_SUCCESS.type:
            return {
                ...state,
                dateOfBirth: action.payload.data.data.attributes.dateOfBirth,
            }
        case MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE.type:
            return {
                ...state,
            }
        case MEMBERS_ACCOUNT_PHONE_NUMBER_UPDATE_SUCCESS.type:
            return {
                ...state,
                phoneNumber: action.payload.data.data.attributes.phoneNumber,
            }
        case MEMBERS_ACCOUNT_EMAIL_UPDATE.type:
            return {
                ...state,
                //TODO: Get the fields added into here
            }
        case MEMBERS_ACCOUNT_EMAIL_UPDATE_SUCCESS.type:
            return {
                ...state,
                // email: action.payload.data.data.attributes.email,
            }
        case MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS.type:
            return {
                ...state,
                preferredName:
                    action.payload.request.data.data.attributes.preferredName,
            }
        case MEMBER_ACCOUNT_UPDATE_PERSONAL_DETAILS_SUCCESS.type:
            return {
                ...state,
                preferredName:
                    action.payload.data.data.attributes.preferredName,
                ...action.payload.data.data.attributes,
            }
        case MEMBERS_ACCOUNT_EDIT_ADDRESS_SUCCESS.type:
            return {
                ...state,
                preferredName:
                    action.payload.data.data.attributes.preferredName,
                ...action.payload.data.data.attributes,
            }
        case "MEMBER_CONFIRM_BANK_SCHEDULE_SUCCESS_STATUS":
            return {
                ...state,
                jwtToken: action.jwtToken,
                tokenExpires: action.tokenExpires,
            }
        case MEMBERS_ACCOUNT_NI_NUMBER_UPDATE_SUCCESS.type:
            return {
                ...state,
                niNumber: action.payload.data.data.attributes.niNumber,
            }
        case MEMBERS_ACCOUNT_NI_NUMBER_UPDATE.type:
            return {
                ...state,
                niNumber: action.payload.request.data.data.attributes.value,
            }
        case MEMBERS_ACCOUNT_PROFILE_ADDRESS_CONFIRM_SUCCESS.type:
            return {
                ...state,
                requiredProfileValues:
                    action.payload.data.data.attributes.requiredProfileValues,
            }
        case MEMBERS_ACCOUNT_PROFILE_NAMES_CONFIRM_SUCCESS.type:
            return {
                ...state,
                requiredProfileValues:
                    action.payload.data.data.attributes.requiredProfileValues,
            }
        case MEMBER_ACCOUNT_UPDATE_MARKETING_PREFERENCES_SUCCESS.type:
            return {
                ...state,
                marketing: JSON.parse(
                    action.payload.data.data.attributes.marketing
                ),
            }
        case REGISTRATION_REFRESH.type:
            return {
                ...state,
                isFetching: true,
                isRefreshingToken: true,
            }
        case REGISTRATION_REFRESH_SUCCESS.type:
            return {
                ...state,
                isFetching: false,
                isRefreshingToken: false,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
            }
        default:
            return state
    }
}

function errors(state = [], action) {
    switch (action.type) {
        case SET_API_FAIL.type:
            return [...(action.errors || [])]
        case CLEAR_API_FAIL.type:
            return []
        case LOGOUT_SUCCESS.type:
            return []
        default:
            return state
    }
}

function register(
    state = {
        isFetching: false,
        isRefreshingToken: false,
        isAuthenticated: false,
        jwtToken: null,
        tokenExpires: null,
        onboarding: false,
        verification: false,
        confirmation: false,
        onboardingStatus: "",
        currentStep: 0,
    },
    action
) {
    switch (action.type) {
        case REGISTRATION_ME_SUCCESS.type:
            return {
                ...state,
                isFetching: false,
                isRefreshingToken: false,
            }
        case MEMBER_SET_STEP.type:
            return {
                ...state,
                currentStep: action.currentStep,
            }
        case REGISTRATION_INIT.type:
            return {
                ...state,
                isFetching: true,
            }
        case REGISTRATION_REFRESH.type:
            return {
                ...state,
                isFetching: true,
                isRefreshingToken: true,
            }
        case REGISTRATION_COMPLETE.type:
            return {
                ...state,
                isFetching: true,
            }
        case REGISTRATION_COMPLETE_SUCCESS.type:
            return {
                ...state,
                onboardingStatus:
                    action.payload.data.data.attributes.onboardingStatus,
                verification: true,
                onboarding: false,
                confirmation: false,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
            }
        case KYC_COMPLETE_SUCCESS.type:
            return {
                ...state,
                verification: false,
                onboarding: false,
                confirmation: false,
                isAuthenticated: true,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
            }

        case REGISTRATION_INIT_SUCCESS.type:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                onboarding: true,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
            }
        case REGISTRATION_REFRESH_SUCCESS.type:
            return {
                ...state,
                isFetching: false,
                isRefreshingToken: false,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
            }

        case LOGIN.type:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
            }
        case LOGIN_FAIL.type:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
            }
        case REGISTRATION_VERIFY_BY_TOKEN_SUCCESS.type:
            return {
                ...state,
                verification: false,
                confirmation: true,
                onboardingStatus:
                    action.payload.data.data.attributes.onboardingStatus,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
            }
        case REGISTRATION_VERIFY_BY_CODE_SUCCESS.type:
            return {
                ...state,
                verification: false,
                confirmation: true,
                onboardingStatus:
                    action.payload.data.data.attributes.onboardingStatus,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
            }
        case LOGIN_SUCCESS.type:
            return {
                ...state,
                isAuthenticated:
                    !action.payload.data.data.attributes.hasOwnProperty(
                        "onboardingStatus"
                    ),
                isFetching: false,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
                onboarding: false,
                verification:
                    action.payload.data.data.attributes.onboardingStatus ===
                    "VERIFICATION",
                confirmation:
                    action.payload.data.data.attributes.onboardingStatus ===
                    "CONFIRMATION",
                onboardingStatus:
                    action.payload.data.data.attributes.onboardingStatus ||
                    null,
                currentStep:
                    action.payload.data.data.attributes.onboardingStatus ===
                    "VERIFICATION"
                        ? 4
                        : null,
            }
        case LOGIN_REFRESH.type:
            return {
                ...state,
                isFetching: true,
                isRefreshingToken: true,
            }
        case LOGIN_REFRESH_SUCCESS.type:
            return {
                ...state,
                isAuthenticated: true,
                isFetching: false,
                isRefreshingToken: false,
                jwtToken: action.payload.data.data.attributes.jwtToken,
                tokenExpires: action.payload.data.data.attributes.tokenExpires,
                onboarding: false,
                verification:
                    action.payload.data.data.attributes.onboardingStatus ===
                    "VERIFICATION",
                confirmation:
                    action.payload.data.data.attributes.onboardingStatus ===
                    "CONFIRMATION",
                onboardingStatus:
                    action.payload.data.data.attributes.onboardingStatus ||
                    null,
                currentStep: null,
            }
        case LOGOUT_SUCCESS.type:
            windowGlobal && windowGlobal.localStorage.clear()
            return {
                isFetching: false,
                isRefreshingToken: false,
                isAuthenticated: false,
                jwtToken: null,
                tokenExpires: null,
                onboarding: false,
                verification: false,
                confirmation: false,
                onboardingStatus: "",
                currentStep: 0,
            }
        default:
            return state
    }
}
function pollNames(state = initialPollNamesState, action) {
    switch (action.type) {
        case "TOGGLE_POLLING":
            return {
                ...state,
                isPolling: action.payload,
            }
        case LOGOUT_SUCCESS.type:
            return initialPollNamesState
        default:
            return state
    }
}
function selectedAccount(state = initialSelectedAccount, action) {
    switch (action.type) {
        case "SELECT_ACCOUNT":
            return {
                ...state,
                type: action.payload.type,
                id: action.payload.id,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
            }
        case SCHEDULE_A_SEARCH_RESULTS_SUCCESS.type:
            let matches =
                action.payload?.data?.included?.filter(
                    includes =>
                        includes.type === "match" &&
                        includes.attributes.status === "NEW" &&
                        includes.relationships?.thirdParty
                ) || []
            let thirdPartyIds =
                matches.map(match => match.relationships.thirdParty.data.id) ||
                []
            let uniqueIds = [...new Set(thirdPartyIds)]
            let stats = uniqueIds.map(id => {
                return {
                    id: id,
                    count: thirdPartyIds.filter(tPID => tPID === id).length,
                }
            })
            return {
                ...state,
                uniqueIds: uniqueIds ?? [],
                stats: stats ?? [],
            }
        case LOGOUT_SUCCESS.type:
            return initialSelectedAccount
        default:
            return state
    }
}
function dashboardRecommendedActions(state = [], action) {
    switch (action.type) {
        case SCHEDULE_A_SEARCH_RESULTS_SUCCESS.type:
            return action.payload.data.data.attributes.recommendedActions || []
        default:
            return state
    }
}
const handleEmailContent = data => {
    if (data.data?.attributes?.velocityRule === "true") {
        return "ERROR_VELOCITY"
    }
    if (data.data.attributes.contactMethod === ContactMethods.EMAIL) {
        return atob(data.data.attributes?.subjectAccessRequestBase64)
    }
}
function dsarContacts(state = initialDsarState, action) {
    switch (action.type) {
        case SEARCH_DSAR_CONTACTS.type:
            return {
                ...state,
                isSearching: true,
                searchQuery: action.payload.request.params.query,
                hasSearched: true,
            }
        case "PURGE_DSAR_CONTACTS":
            return initialDsarState
        case LOGOUT_SUCCESS.type:
            return initialDsarState
        case SEARCH_DSAR_CONTACTS_SUCCESS.type:
            return {
                ...state,
                isSearching: false,
                hasSearched: true,
                results:
                    action.payload.status === 200
                        ? action.payload.data?.data
                        : [],
                links:
                    action.payload.status === 200
                        ? action.payload.data.links
                        : {},
            }
        case SEARCH_DSAR_CONTACTS_FAIL.type:
            return {
                ...state,
                isSearching: false,
                results: [],
                hasErrored: true,
            }
        case GET_DSAR_CONTACT_SUCCESS.type:
            return {
                ...state,
                isSearching: false,
                subjectAccessRequest: {
                    ...action.payload.data.data,
                    attributes: {
                        ...action.payload.data.data.attributes,
                        velocityRule:
                            action.payload.data.data.attributes.hasOwnProperty(
                                "velocityRule"
                            )
                                ? JSON.parse(
                                      action.payload.data.data.attributes
                                          .velocityRule
                                  ) === true
                                : false,
                        content: handleEmailContent(action.payload.data),
                    },
                },
            }
        default:
            return state
    }
}
export default combineReducers({
    dsarContacts,
    affiliate,
    register,
    member,
    thirdPartyAccounts,
    avatars,
    errors,
    countries,
    firstPartySearchResults,
    lookupAddresses,
    onboarding,
    previousAddresses,
    previousNames,
    consentStatusResults,
    notifications,
    uploadDocument,
    connectedResults,
    claimedResults,
    thirdPartyMatches,
    pollNames,
    partners,
    thirdPartyNames,
    thirdPartyPreviousNames,
    selectedAccount,
    thirdPartyClaimedResults,
    dashboardRecommendedActions,
})
