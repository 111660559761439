const atSingular = {
    EQUITY: "shares",
    BANK_ACCOUNT: "bank account",
    PENSION: "pension",
    CTF: "child trust fund",
    INVESTMENT: "investment",
    INSURANCE: "life insurance policy",
}
const atPlural = {
    EQUITY: "shares",
    BANK_ACCOUNT: "bank accounts",
    PENSION: "pensions",
    CTF: "child trust funds",
    INVESTMENT: "investments",
    INSURANCE: "life insurance policies",
}
const generateBrandPath = brand => {
    return (
        "/find-lost-" +
        atPlural[brand.attributes.primaryAssetClass].replaceAll(" ", "-") +
        "/lost-" +
        brand.attributes.slug +
        "-" +
        atSingular[brand.attributes.primaryAssetClass].replaceAll(" ", "-") +
        "-search"
    )
}
module.exports = {
    generateBrandPath: generateBrandPath,
    atSingular: atSingular,
    atPlural: atPlural,
}
